<template>
  <v-stepper-content step="2">
    <validation-observer ref="step2formValidationObserver">
      <v-form v-model="step2Valid" lazy-validation ref="step2form">
        <v-card flat>
          <div class="pt-1 mt-1"></div>
          <div v-if="quoteType != quoteTypes.igsPolicyLabel">
            <p>
              Add any additional repairs your vehicle may need by selecting the
              type of repair from the list on the on the right. If you are
              unsure of which repair to select or if your required repair is not
              listed, please select the “explain issue” option.
            </p>
            <v-divider role="presentation"></v-divider>
            <div class="pt-1 mt-1"></div>
            <v-btn-toggle
              v-model="quoteRequest.partsPreference"
              class="smc-service-type"
              :style="
                this.$vuetify.breakpoint.smAndDown
                  ? 'display: inline-block'
                  : ''
              "
              rounded
              dense
              active-class="primary"
            >
              <v-btn
                id="createQutoe-partsTypcurrentStep"
                class="smc-text-button my-1"
                value="Original parts"
                width="150"
              >
                Original parts
              </v-btn>
              <v-btn
                id="createQutoe-partsType2"
                class="smc-text-button my-1"
                value="Generic parts"
                width="150"
              >
                Generic parts
              </v-btn>
              <v-btn
                id="createQutoe-partsType3"
                class="smc-text-button my-1"
                value="I don't mind"
                width="150"
              >
                I don't mind
              </v-btn>
            </v-btn-toggle>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <v-icon v-on="on" :color="hover ? 'primary' : 'secondary'">
                    mdi-help-circle
                  </v-icon>
                </v-hover>
              </template>
              <span>
                Original parts refer to parts specifically made and sold by your
                vehicle's manufacturer.
                <br />Generic parts are good quality alternatives.
              </span>
            </v-tooltip>
            <v-expand-transition>
              <div v-if="quoteRequest.partsPreference == 'Original parts'">
                <div class="py-1 my-1"></div>
                <v-card-title
                  class="smc-card-reduced-heading primary white--text mb-4"
                >
                  License disk information
                </v-card-title>
                <v-card-subtitle class="mt-2">
                  We require the following information because you requested
                  original parts. Our reputable workshop network require at
                  least a VIN number in order to order original OEM parts.
                </v-card-subtitle>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12" sm="9" md="11">
                        <validation-provider
                          mode="lazy"
                          v-slot="{ errors }"
                          name="Vehicle VIN no"
                          :rules="`anyRequired:@licenseDiskPhoto|min:17|max:${vinLength}`"
                        >
                          <v-text-field
                            id="createQuote-vehicleVinNumber"
                            v-model="quoteRequest.vehicleVinNumber"
                            :error-messages="errors"
                            label="Vehicle VIN no"
                            outlined
                            rounded
                            prepend-inner-icon="mdi-barcode-scan"
                            :counter="vinLength"
                          />
                        </validation-provider>
                        <v-alert
                          class="text-left"
                          type="info"
                          outlined
                          border="left"
                          v-if="
                            quoteRequest.vehicleVinNumber &&
                            quoteRequest.vehicleVinNumber.length == vinLength &&
                            !validateVin(quoteRequest.vehicleVinNumber)
                          "
                        >
                          It looks like your VIN number might not be correct.
                          <br />
                          Please note that the following letters never appear in
                          VIN numbers: I, O, Q
                          <br />
                          If you are sure that the VIN number is correct, you
                          can proceed
                        </v-alert>
                      </v-col>
                      <v-col
                        v-if="this.$vuetify.breakpoint.smAndUp"
                        class="pt-6 px-0"
                      >
                        <image-tooltip
                          v-bind:imageNameProp="'license_disk_vin.png'"
                        ></image-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="divider-column shrink">
                    <v-row v-if="this.$vuetify.breakpoint.mdAndUp">
                      <v-col>
                        <v-divider role="presentation" vertical></v-divider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-chip color="darkGrey" class="white--text">OR</v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-if="this.$vuetify.breakpoint.mdAndUp">
                      <v-col>
                        <v-divider role="presentation" vertical></v-divider>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" md="5">
                    <validation-provider
                      v-slot="{ errors }"
                      name="License disk photo"
                      id="licenseDiskPhoto"
                      rules="image|size:10000"
                    >
                      <v-file-input
                        id="createQuote-licenseDiskPhoto"
                        v-model="quoteRequest.LicenseDiskPhoto"
                        :error-messages="errors"
                        label="License disk photo"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon
                        outlined
                        rounded
                        counter
                        show-size
                        accept="image/*"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="primary accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>
                          <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </validation-provider>
                    <v-img
                      :src="licenseDiskPhotoPreview"
                      :lazy-src="licenseDiskPhotoPreview"
                      max-height="200px"
                      contain
                    ></v-img>
                  </v-col>
                </v-row>
                <v-divider role="presentation"></v-divider>
                <div class="py-1 my-1"></div>
              </div>
            </v-expand-transition>
            <div class="pt-1 mt-1"></div>
          </div>
          <v-row>
            <v-col>
              <!--max-width="400"-->
              <v-card
                class="sticky-top animate-transition mx-1"
                :class="!showRepairList ? 'added-repairs-center' : ''"
                min-width="268"
                raised
              >
                <v-card-title
                  class="white--text primary smc-card-reduced-heading"
                  color="white"
                >
                  <span> What to Expect </span>
                </v-card-title>
                <v-list tile flat>
                  <v-list-item-group color="primary">
                    <v-scroll-x-transition group ref="repairItemList">
                      <!--
                      <v-list-item :key="-3" v-if="hasMaintenanceService">
                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                          <v-icon>mdi-engine</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-left body-2">
                            {{ annualServiceType }}
                            <v-progress-circular
                              v-if="serviceIntervalLoading"
                              :size="20"
                              class="float-right"
                              color="primary"
                              indeterminate
                            ></v-progress-circular>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      -->
                      <v-list-item
                        :key="-1"
                        v-if="
                          servicesCount == 0
                        "
                      >
                        <v-list-item-icon v-if="$vuetify.breakpoint.smAndUp">
                          <v-icon>mdi-wrench</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-left body-2">
                            Add a repair item from the list
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <template v-for="item in leafFilteredRepairItemsTree">
                        <v-list-item-group
                          :key="item.id"
                          v-if="item.name != 'Maintenance Service'"
                        >
                          <v-list-item
                            :class="!item.repairItemTemplateItems || item.repairItemTemplateItems.length == 0 ? 'mb-2' : ''"
                          >
                            <v-list-item-icon
                              v-if="$vuetify.breakpoint.smAndUp"
                            >
                              <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="py-0 pr-2">
                              <v-list-item-title class="text-left body-2">
                                <span>
                                  <label
                                    :ref="'addedServiceItem' + item.id"
                                    :id="'addedServiceItem' + item.id"
                                    class="text-wrap"
                                  >
                                    {{ item.name }}
                                  </label>
                                </span>
                                <image-tooltip
                                  v-if="
                                    item.name
                                      .toLowerCase()
                                      .includes('tyre replacement')
                                  "
                                  v-bind:imageNameProp="`tyre-size-img.png`"
                                ></image-tooltip>
                                <validation-provider
                                  v-if="item.hasQuery"
                                  v-slot="{ errors }"
                                  :name="`addedServiceDetails${item.id}`"
                                  rules="required:true"
                                >
                                  <v-textarea
                                    :id="`createQuote-addedServices-details-${item.id}`"
                                    :error-messages="errors"
                                    class="smc-textinput-dense"
                                    v-model="item.queryAnswer"
                                    :hint="item.query"
                                    persistent-hint
                                    rows="1"
                                    dense
                                    auto-grow
                                    clearable
                                    :counter="$vuetify.breakpoint.smAndUp"
                                    outlined
                                    required
                                    maxLength="500"
                                  ></v-textarea>
                                </validation-provider>
                              </v-list-item-title>
                            </v-list-item-content>
                              <v-btn
                                :id="`createQuote-addedServices-remove-${item.id}`"
                                icon
                                v-if="item.repairItemTemplateItems && item.repairItemTemplateItems.length != item.baseRepairItemTemplateItems.length"
                                @click="restoreRepairItemTemplateItems(item)"
                              >
                                <v-icon>mdi-restore</v-icon>
                              </v-btn>
                              <v-btn
                                :id="`createQuote-addedServices-remove-${item.id}`"
                                icon
                                @click="removeRepairItem(item.id)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                          </v-list-item>

                          <v-list-item-group
                            class="mb-6"
                            v-if="
                              item.repairItemTemplateItems &&
                              item.repairItemTemplateItems.length > 0
                            "
                          >
                            <v-list-item
                              class="ml-6 service-interval-item"
                              v-for="(
                                template, index
                              ) in item.repairItemTemplateItems"
                              :key="index"
                            >
                              <v-list-item-content class="py-0">
                                <v-list-item-title class="text-left body-2">
                                  {{ template.description }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-avatar
                                v-if="quoteType != quoteTypes.igsPolicyLabel"
                              >
                                <v-btn
                                  icon
                                  @click="removeTemplateItem(item, template)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </v-list-item-avatar>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list-item-group>
                      </template>
                    </v-scroll-x-transition>
                  </v-list-item-group>
                </v-list>
                <v-divider></v-divider>
                <!--
                <v-card-actions v-if="quoteType == quoteTypes.smcServiceLabel">
                  <v-row>
                    <v-col class="text-right">
                      <v-btn
                        color="primary"
                        right
                        rounded
                        small
                        @click="showRepairList = !showRepairList"
                      >
                        <span v-if="!showRepairList"> Show repairs </span>
                        <span v-else> Hide repairs </span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-actions>
                -->
              </v-card>
            </v-col>
            <v-slide-x-reverse-transition
              v-if="quoteType !== quoteTypes.igsPolicyLabel"
            >
              <v-col v-if="showRepairList">
                <!--max-width="400"-->
                <v-card class="mx-1" tile>
                  <v-sheet class="pa-2">
                    <v-text-field
                      id="createQuote-treeSearch"
                      v-model="treeSearch"
                      label="Search for service"
                      rounded
                      outlined
                      hide-details
                      clearable
                    ></v-text-field>
                  </v-sheet>
                  <v-treeview
                    id="createQuote-servicesTree"
                    class="repairs-treeview"
                    :items="repairItemsTreeModel"
                    selection-type="independent"
                    selected-color="primary"
                    item-text="displayName"
                    :search="treeSearch"
                    v-model="quoteRequest.items"
                    selectable
                    hoverable
                    open-on-click
                    return-object
                    rounded
                    transition
                    :multiple-active="true"
                  >
                    <template v-slot:label="{ item }">
                      <v-btn
                        :id="`createQuote-services-${item.id}`"
                        text
                        class="float-left text-wrap text-none body-2 font-weight-regular smc-text-button"
                        @click="treeItemClicked(item)"
                        :height="$vuetify.breakpoint.smAndDown ? 60 : 36"
                      >
                        {{ item.displayName }}
                      </v-btn>
                    </template>
                    <template v-slot:append="{ item }">
                      <v-icon v-if="item.trunk">
                        {{ item.icon }}
                      </v-icon>
                    </template>
                  </v-treeview>
                </v-card>
              </v-col>
            </v-slide-x-reverse-transition>
          </v-row>
          <v-row>
            <v-col cols="2" class="d-none d-md-flex"></v-col>
            <v-col>
              <v-expand-transition>
                <v-alert
                  v-if="noServicesError"
                  class="mb-0"
                  border="left"
                  type="error"
                >
                  Please add at least one service or repair to your request.
                </v-alert>
              </v-expand-transition>
            </v-col>
            <v-col cols="2" class="d-none d-md-flex"></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                id="createQuote-step2Back"
                rounded
                color="secondary"
                class="smc-text-button smc-medium-button mx-1 my-1"
                @click="goStep(1)"
                :disabled="stepLoading"
              >
                <span v-if="!stepLoading"> Back </span>
                <v-progress-circular
                  v-else
                  :size="25"
                  indeterminate
                  color="secondary"
                ></v-progress-circular>
              </v-btn>
              <v-btn
                id="createQuote-step2Next"
                rounded
                color="primary"
                class="smc-text-button smc-medium-button mx-1 my-1"
                @click="stepForward(3)"
                :disabled="!step2Valid || stepLoading"
              >
                <span v-if="!stepLoading" v-text="'Next'"></span>
                <v-progress-circular
                  v-else
                  :size="25"
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </validation-observer>
    <v-snackbar
      color="primary"
      v-model="addedSnackbar"
      bottom
      :class="$vuetify.breakpoint.xsOnly ? 'pb-0' : 'pb-6'"
    >
      {{ addedItemName }} has been added to your request.
    </v-snackbar>
  </v-stepper-content>
</template>

<script>
import quoteRequestCreate from "@/mixins/quote-request-create";
import validate from "@/mixins/validator";
import _ from "lodash";

export default {
  name: "create-step-2",
  mixins: [quoteRequestCreate, validate],
  data: () => ({
    addedSnackbar: false,
    showRepairList: false,
    addedItemName: "",
  }),
  computed: {
    vinLength() {
      var overrides = process.env.VUE_APP_VIN_LENGTH_OVERRIDES;
      if (overrides) {
        var vm = this;
        var overrideList = JSON.parse(overrides);
        var brandOverride = _.find(overrideList, function (item) {
          return _.toUpper(vm.quoteRequest.vehicle.brand).includes(
            _.toUpper(item.brand)
          );
        });
        if (brandOverride) {
          return brandOverride.vin_length;
        }
      }

      return 17;
    },
    serviceIntervalMappings() {
      return [
        {
          name: "Minor Service - Petrol",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
          ],
        },
        {
          name: "Major Service - Petrol",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
            ("Cabin Filter", 0),
          ],
        },
        {
          name: "Major Service with Spark Plugs - Petrol",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
            ("Cabin Filter", 0),
            ("Spark Plugs", 0),
          ],
        },
        {
          name: "Major Service with Timing Belt/Chain Replacement - Petrol",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
            ("Cabin Filter", 0),
            ("Spark Plugs", 0),
            ("Timing Belt", 0),
          ],
        },
        {
          name: "Minor Service - Diesel",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
          ],
        },
        {
          name: "Major Service - Diesel",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
            ("Cabin Filter", 0),
            ("Diesel Fuel Filter", 0),
          ],
        },
        {
          name: "Major Service with Timing Belt/Chain Replacement - Diesel",
          intervalItems: [
            ("Engine Oil", 0),
            ("Oil Filter", 0),
            ("Air Filter", 0),
            ("Cabin Filter", 0),
            ("Diesel Fuel", 0),
            ("Filter	Timing Chain", 0),
          ],
        },
        {
          name: "Headlights",
          intervalItems: [
            ("Replace Front Left Headlight", 0),
            ("Replace Front Right Headlight", 0),
            ("Repair Front Left Headlight", 0),
            ("Repair Front Right Headlight", 0),
          ],
        },
        {
          name: "Fog Lights",
          intervalItems: [
            ("Replace Left Foglight", 0),
            ("Replace Right Foglight", 0),
            ("Repair Left Foglight", 0),
            ("Repair Right Foglight", 0),
          ],
        },
        {
          name: "Indicators",
          intervalItems: [
            ("Replace Front Left Indicator", 0),
            ("Replace Front Right Indicator", 0),
            ("Replace Front Left Quarter Panel Indicator", 0),
            ("Replace Front Right Quarter Panel Indicator", 0),
            ("Replace Rear Left Indicator", 0),
            ("Replace Rear Right Indicator", 0),
            ("Repair Front Left Indicator", 0),
            ("Repair Front Right Indicator", 0),
            ("Repair Front Left Quarter Panel Indicator", 0),
            ("Repair Front Right Quarter Panel Indicator", 0),
            ("Repair Rear Left Indicator", 0),
            ("Repair Rear Right Indicator", 0),
          ],
        },
        {
          name: "Taillights",
          intervalItems: [
            ("Replace Left Taillight", 0),
            ("Replace Right Taillight", 0),
            ("Replace Center Taillight", 0),
            ("Repair Left Taillight", 0),
            ("Repair Right Taillight", 0),
            ("Repair Center Taillight", 0),
          ],
        },
        {
          name: "Shock Absorbers",
          intervalItems: [
            "Front Left Shock Absorber",
            "Front Right Shock Absorber",
            "Rear Left Shock Absorber",
            "Rear Right Shock Absorber",
          ],
        },
        {
          name: "Side Mirror Repairs",
          intervalItems: ["Front Left Side Mirror", "Front Right Side Mirror"],
        },
      ];
    },
  },
  created() {
    this.showRepairList = true;
  },
  watch: {
    leafFilteredRepairItemsTree: function (newValue, oldValue) {
      var item = _.differenceBy(newValue, oldValue, "id");
      if (item !== undefined && item.length > 0) {
        this.addedItemName = item[0].name;
        this.addedSnackbar = true;
      }
    },
    servicesCount: function () {
      this.noServicesError = false;
    },
  },
  methods: {
    removeTemplateItem(repairItem, templateItem) {
      var index = _.findIndex(repairItem.repairItemTemplateItems, [
        "description",
        templateItem.description,
      ]);
      if (index != -1) {
        repairItem.repairItemTemplateItems.splice(index, 1);
      }
    },
    restoreRepairItemTemplateItems(repeairItem) {
      repeairItem.repairItemTemplateItems = _.clone(repeairItem.baseRepairItemTemplateItems);
    }
  },
};
</script>

<style lang="scss" scoped>
.element-ripple {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  animation: pulse 1s;
  background-color: white;
  padding: 5px;
}

@keyframes pulse {
  0% {
    background-color: white;
  }
  100% {
    background-color: #ed3237;
    color: white;
  }
}

.added-repairs-center {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>

<style lang="scss">
#createQuote-servicesTree .v-treeview-node__level {
  @media screen and (min-width: 601px) and (max-width: 960px) {
    width: 6px;
  }

  @media screen and (max-width: 600px) {
    width: 0;
  }
}

.service-interval-item {
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #787878;
  border-left: 1px solid #787878;
  border-right: 1px solid #787878;
}

.service-interval-item ~ .service-interval-item {
  border-top: none;
}
</style>