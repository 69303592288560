var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"fab":"","fixed":"","right":"","bottom":"","small":_vm.$vuetify.breakpoint.smOnly,"x-small":_vm.$vuetify.breakpoint.xsOnly},on:{"click":function($event){return _vm.toggleShow()}}},[_c('v-img',{attrs:{"src":require('@/assets/images/contact-us-icon.svg'),"height":_vm.$vuetify.breakpoint.xsOnly
          ? '35'
          : _vm.$vuetify.breakpoint.smOnly
          ? '45'
          : '60',"width":_vm.$vuetify.breakpoint.xsOnly
          ? '35'
          : _vm.$vuetify.breakpoint.smOnly
          ? '45'
          : '60'}})],1),_c('v-slide-x-reverse-transition',[(_vm.show)?_c('v-card',{staticClass:"smc-contact-card text-left",attrs:{"width":"320","elevation":"6"}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Contact us")]),_c('v-spacer'),_c('v-btn',{staticClass:"white--text",attrs:{"icon":""},on:{"click":function($event){return _vm.setShow(false)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',{staticClass:"subtitle-1"},[_vm._v(" If you need assistance, please do not hesitate to call or email us! ")]),_c('v-card-text',[_c('v-list',{staticClass:"pa-0",attrs:{"nav":""}},[_c('v-list-item',{staticClass:"ma-0",attrs:{"href":("tel:" + _vm.contactPhone)}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-phone-forward")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.contactPhone))])],1)],1),_c('v-list-item',{staticClass:"ma-0",attrs:{"href":("https://wa.me/" + _vm.whatsappNumber),"target":"_blank"}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-whatsapp")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("065 228 2401")])],1)],1),_c('v-list-item',{staticClass:"ma-0",attrs:{"href":("mailto:" + _vm.contactEmail)}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.contactEmail))])],1)],1)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }