<template>
  <v-card outlined color="#33393f" :loading="loading">
    <v-card-text class="price-lookup-cards-container">
      <v-card v-if="quoteRequestSearchMode">
        <v-card-text>
            <v-form @submit.prevent="searchLookups">
          <v-row>
            <v-col class="grow">
              <v-text-field
                hide-details
                dense
                label="Search"
                placeholder="Type to search"
                v-model="quoteRequestItem.name"
              ></v-text-field>
            </v-col>
            <v-col class="shrink">
              <v-btn outlined color="primary" @click="searchLookups">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        </v-card-text>
      </v-card>
      <v-expand-transition>
        <div v-if="!initialized && loading">
          <v-card class="price-lookup-card">
            <v-skeleton-loader type="table-heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
          </v-card>
          <v-card class="price-lookup-card">
            <v-skeleton-loader type="table-heading"></v-skeleton-loader>
            <v-skeleton-loader type="image"></v-skeleton-loader>
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
          </v-card>
        </div>
      </v-expand-transition>
      <v-expand-transition>
        <div v-if="initialized">
          <v-card
            class="price-lookup-card"
            v-for="quote in quotes"
            :key="quote.id"
          >
            <v-card-title class="mb-3 py-6">
              <span class="mx-auto">
                {{ dateHelper.formatDateLong(quote.dateCreated) }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="grow">
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Year</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.year"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.year !=
                          quote.vehicleMapping.vehicle.year
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.year !=
                          quote.vehicleMapping.vehicle.year
                            ? 'mdi-alert'
                            : ''
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Brand</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.brand"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.brand !=
                          quote.vehicleMapping.vehicle.brand
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.brand !=
                          quote.vehicleMapping.vehicle.brand
                            ? 'mdi-alert'
                            : ''
                        "
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Range</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.range"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.range !=
                          quote.vehicleMapping.vehicle.range
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.range !=
                          quote.vehicleMapping.vehicle.range
                            ? 'mdi-alert'
                            : ''
                        "
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Model</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.model"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.model !=
                          quote.vehicleMapping.vehicle.model
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.model !=
                          quote.vehicleMapping.vehicle.model
                            ? 'mdi-alert'
                            : ''
                        "
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Fuel Type</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.fuelType"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.fuelType !=
                          quote.vehicleMapping.vehicle.fuelType
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.fuelType !=
                          quote.vehicleMapping.vehicle.fuelType
                            ? 'mdi-alert'
                            : ''
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="my-0 mb-n2">
                    <v-col class="py-0" cols="3">
                      <v-subheader>Transmission Type</v-subheader>
                    </v-col>
                    <v-col class="py-0">
                      <v-text-field
                        hide-details
                        class="mb-0"
                        :value="quote.vehicleMapping.vehicle.transmissionType"
                        readonly
                        filled
                        dense
                        :background-color="
                          quoteRequestProp.vehicle.transmissionType !=
                          quote.vehicleMapping.vehicle.transmissionType
                            ? 'warning'
                            : ''
                        "
                        :append-icon="
                          quoteRequestProp.vehicle.transmissionType !=
                          quote.vehicleMapping.vehicle.transmissionType
                            ? 'mdi-alert'
                            : ''
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="shrink">
                  <div class="smc-card-avatar" style="width: 320px">
                    <img
                      :src="quote.vehicleMapping.vehicle.imageUrl"
                      class="smc-search-result-image"
                    />
                  </div>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Description</th>
                        <th class="text-right">Unit Price</th>
                        <th class="text-right">Quantity</th>
                        <th class="text-right">Line Total</th>
                      </tr>
                    </thead>
                    <thead>
                      <tr>
                        <th colspan="5" class="quote-request-item-name py-2">
                          {{ quote.quoteRequestItems[0].name }}
                          <v-tooltip
                            bottom
                            v-if="quoteRequestItemProp && quote.quoteRequestItems[0].name !== quoteRequestItemProp.name"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon 
                                v-bind="attrs"
                                v-on="on"
                                color="orange" 
                                dense
                              >
                                mdi-alert-circle
                              </v-icon>
                            </template>
                            <span>Not an exact match</span>
                          </v-tooltip>
                          <v-card class="mt-2" v-if="quote.quoteRequestItems[0].queryAnswer">
                            <v-card-text class="pa-1 caption">
                              <strong>{{ quote.quoteRequestItems[0].query }}:</strong>
                              {{ quote.quoteRequestItems[0].queryAnswer }}
                            </v-card-text>
                          </v-card>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(quoteItem, index) in quote.quoteRequestItems[0]
                          .quoteItems"
                        :key="index"
                      >
                        <td>{{ index + 1 }}</td>
                        <td class="text-left">{{ quoteItem.description }}</td>
                        <td class="text-right">
                          {{ quoteItem.priceIncl | numeral("$ 0,0.00") }}
                        </td>
                        <td class="text-right">{{ quoteItem.quantity }}</td>
                        <td class="text-right">
                          {{ quoteItem.totalIncl | numeral("$ 0,0.00") }}
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="5" class="text-right">
                          Total:
                          {{
                            _.sumBy(
                              quote.quoteRequestItems[0].quoteItems,
                              "totalIncl"
                            ) | numeral("$ 0,0.00")
                          }}
                        </th>
                      </tr>
                    </tfoot>
                  </v-simple-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            class="price-lookup-card"
            v-if="quotes.length == 0"
            height="500"
          >
            <h3 class="pt-12">No results found</h3>
          </v-card>
        </div>
      </v-expand-transition>

      <v-row class="mt-2" v-if="hasMore">
        <v-spacer></v-spacer>
        <v-col>
          <v-btn
            color="info"
            @click="loadPriceLookups"
            :loading="initialized && loading"
          >
            Load more
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import { quoteRequestService } from "@/services";
import { dateHelper } from "@/helpers";

export default {
  name: "quote-price-item-lookup",
  props: {
    quoteRequestSearchMode: Boolean,
    quoteRequestItemProp: Object,
    quoteRequestProp: Object,
  },
  data: () => ({
    _,
    dateHelper,

    initialized: false,
    loading: false,
    hasMore: false,

    quoteRequestItem: {
      name: "",
    },
    quotes: [],

    indexCounter: 0,
    size: 5,
  }),
  async created() {
    if (this.quoteRequestItemProp) {
      this.quoteRequestItem = this.quoteRequestItemProp;
      await this.loadPriceLookups();
    }
  },
  methods: {
    async searchLookups() {
      this.quotes = [];
      this.hasMore = false;
      this.indexCounter = 0;
      if (this.quoteRequestItem.name.length > 0) {
        await this.loadPriceLookups();
      }
    },
    async loadPriceLookups() {
      this.loading = true;
      let payload = {
        skip: this.indexCounter * this.size,
        size: this.size,
        vehicle: {
          year: this.quoteRequestProp.vehicle.year,
          brand: this.quoteRequestProp.vehicle.brand,
          range: this.quoteRequestProp.vehicle.range,
        },
        quoteRequestItem: this.quoteRequestItem,
      };

      var quoteLookups = await quoteRequestService.queryQuotesPriceLookup(
        payload
      );
      if (quoteLookups && quoteLookups.length > 0) {
        this.quotes.push(...quoteLookups);
        if (quoteLookups.length < 5) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
        }
      } else {
        this.hasMore = false;
      }

      this.indexCounter++;
      this.initialized = true;
      this.loading = false;
    },
  },
};
</script>

<style>
.v-card__text.price-lookup-cards-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.price-lookup-card:nth-child(1) {
  margin-top: 20px;
}

.price-lookup-card:nth-child(n + 2) {
  margin-top: 40px;
}

.price-lookup-card .v-card__title {
  background-color: #e0e0e0;
}

.price-lookup-card .v-input {
  font-size: 14px;
}

.price-lookup-card .v-input input {
  padding: 0 !important;
}

.price-lookup-card .v-card__title {
  font-size: 16px;
}

.price-lookup-card .v-data-table table {
  border: 1px solid #e0e0e0 !important;
}

.price-lookup-card .v-data-table tfoot tr th {
  border-top: 1px solid #e0e0e0;
}

.price-lookup-card .v-input__control {
  border-radius: 0;
}

.quote-request-item-name {
  text-align: center !important;
  background-color: #e0e0e0;
  /*color: #fff !important;*/
}
</style>