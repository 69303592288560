import analytics from "@/mixins/analytics";
import { placeService, quoteRequestService } from "@/services";
import { mask } from "vue-the-mask";
import {
    validation,
    mapHelper,
    addressHelper,
    formFunctions,
    quoteTypes,
} from "@/helpers";
import carFlag from "@/components/widgets/car-flag";
import imageTooltip from "@/components/widgets/image-tooltip";
import { gmapApi } from "vue2-google-maps";
import VueRecaptcha from 'vue-recaptcha';
import _ from "lodash";
import { userHelper } from "../helpers/user-helper";
import { setTimeout } from "core-js";

export default {
    directives: {
        mask
    },
    props: {
        vehicleProp: Object,
        quoteTypeProp: String,
        quoteRequestTypeProp: String
    },
    components: {
        VueRecaptcha,
        imageTooltip,
        carFlag
    },
    mixins: [analytics],
    data: function () {
        return {
            gotoDuration: 350,
            //gotoOffset: 50,
            gotoEasing: 'linear',
            isMounted: false,
            recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY_V2_INVISIBLE,
            addressHelper,
            quoteRequestReference: "",
            noServicesError: false,
            noDatesError: false,
            workshopFilterLoading: false,
            workshopFilter: "",

            // map stuff
            // showWorkshopList: undefined,
            workshopsSelected: [],
            organizationWorkshopPage: 1,

            place: null,
            autocompleteLocationModel: null,
            locationSearchText: null,
            locationEntries: [],
            autocompleteSessionToken: undefined,
            addressItems: [],
            centerPosition: mapHelper.defaultCenterPosition(),
            zoom: mapHelper.defaultZoomLevel(),
            mapIsLoading: false,
            radiusDistances: [],
            markerIconWidth: 30,
            markerIconHeight: 48,
            maxTravelDistance: 50,
            minWorkshopMappings: 3,
            maxWorkshopMappingsCount: 0,
            mapNoWorkshopsShow: false,

            //
            stepLoading: false,
            step1Valid: true,
            step2Valid: true,
            step3Valid: true,
            step4Valid: true,
            submitting: false,
            submitted: false,
            recaptchaValid: false,
            validationMessages: [],
            duplicateValidationShow: false,
            duplicateValidation: false,
            duplicateQuoteValidation: false,
            //serviceIntervalLoading: false,

            fiveSecondsPassed: false,
            nakedRedirectQueued: false,
            dateMenu: false,
            timeMenu: false,
            timeRequested: "",
            treeSearch: null,
            licenseDiskPhotoPreview: require('../assets/images/license-disk-img.png'),
            rules: validation.validation_rules,
            quoteTypes,
            socialLinks: {
                facebook: process.env.VUE_APP_SOCIAL_FACEBOOK,
                twitter: process.env.VUE_APP_SOCIAL_TWITTER,
                instagram: process.env.VUE_APP_SOCIAL_INSTAGRAM,
                linkedin: process.env.VUE_APP_SOCIAL_LINKEDIN
            }
        }
    },
    computed: {
        currentStep: {
            get: function () {
                let currentStep = this.$store.getters["quoteRequestCreate/getCurrentStep"];
                return currentStep != 0 ? currentStep : 1;
            },
            set: function (newVal) {
                this.$store.dispatch("quoteRequestCreate/setCurrentStep", newVal);
            }
        },
        quoteType() {
            return this.$store.getters["quoteRequestCreate/getQuoteType"];
        },
        isBusiness: {
            get: function () {
                return this.$store.getters["quoteRequestCreate/getIsBusiness"];
            },
            set: function (newVal) {
                this.$store.dispatch("quoteRequestCreate/setIsBusiness", newVal);
            }
        },
        showWorkshopList: {
            get: function () {
                return this.$store.getters["quoteRequestCreate/getShowWorkshopList"];
            },
            set: function (newVal) {
                return this.$store.dispatch("quoteRequestCreate/setShowWorkshopList", newVal);
            }
        },
        quoteRequest: {
            get: function () {
                return this.$store.getters["quoteRequestCreate/getQuoteRequest"];
            },
        },
        repairItemsTreeModel() {
            return _.cloneDeep(this.$store.getters["quoteRequestCreate/getRepairItemsTreeModel"]);
        },
        regions() {
            return this.$store.getters["quoteRequestCreate/getRegions"];
        },
        workshopAddresses() {
            var addresses = this.$store.getters["quoteRequestCreate/getWorkshopAddresses"];
            if (this.place) {
                addresses.forEach(addr => {
                    addr.distance = placeService.calculateDistance(this.place.address, addr);
                });

                addresses = _.orderBy(addresses, ['distance', 'asc']);
            }
            return addresses;
        },
        quoteRequestWorkshopMappings() {
            return this.$store.getters["quoteRequestCreate/getQuoteRequestWorkshopMappings"];
        },
        extraQuoteRequestWorkshopMappings() {
            return this.$store.getters["quoteRequestCreate/getExtraQuoteRequestWorkshopMappings"];
        },
        allQuoteRequestWorkshopMappings() {
            if (this.showWorkshopList) {
                return this.$store.getters["quoteRequestCreate/getSelectedWorkshopMappings"];
            }
            else {
                return this.quoteRequestWorkshopMappings.concat(this.extraQuoteRequestWorkshopMappings);
            }
        },
        filteredOrganizationWorkshopList() {
            return _.take(this.organizationWorkshopList, (this.organizationWorkshopPage * 8));
        },
        //quoteServiceInterval() {
        //    return this.$store.getters["quoteRequestCreate/getServiceInterval"];
        //},
        quoteRequestWorkshopMappingsCount() {
            return this.quoteRequestWorkshopMappings.length + this.extraQuoteRequestWorkshopMappings.length;
        },
        gotoOffset() {
            var windowHeight = window.innerHeight;
            return windowHeight / 2;
        },
        gotoOptions() {
            return {
                duration: this.gotoDuration,
                offset: this.gotoOffset,
                easing: this.gotoEasing
            };
        },
        today() {
            return new Date();
        },
        minDate() {
            if (this.isOrganization) {
                return this.today.toISOString();
            }
            else {
                return this.tomorrow;
            }
        },
        tomorrow() {
            var tomorrow = new Date(this.today);
            tomorrow.setDate(tomorrow.getDate() + 2);
            return tomorrow.toISOString();
        },
        twoWeeks() {
            var twoWeeks = new Date(this.today);
            twoWeeks.setDate(twoWeeks.getDate() + 15);
            return twoWeeks.toISOString();
        },
        isAuthenticated() {
            return this.$store.getters["user/isAuthenticated"];
        },
        storeUser: function () {
            return this.$store.getters["user/getUserModel"];
        },
        isOrganization: function () {
            return this.$store.getters["user/isOrganization"];
        },
        organization() {
            return this.$store.getters["user/getOrganization"];
        },
        organizationBranch() {
            if (this.organization.organizationBranches) {
                return this.organization.organizationBranches[0];
            }
            return null;
        },
        organizationWorkshopList() {
            let filteredWorkshops = _.filter(this.workshopAddresses, ["isOrganization", true]);
            if (this.workshopFilter) {
                const filterLowerCase = this.workshopFilter.toLowerCase(); 
                filteredWorkshops = filteredWorkshops.filter(workshop => {
                    const workshopNameLowerCase = workshop.name.toLowerCase();
                    return workshopNameLowerCase.includes(filterLowerCase);
                });
            }

            return filteredWorkshops;
        },
        leafFilteredRepairItemsTree() {
            return this.quoteRequest.items.filter(r => r.leaf);
        },
        annualServiceType() {
            return this.quoteRequest.serviceType &&
                this.quoteRequest.serviceType !== "I'm Not Sure"
                ? "Maintenance service - " + this.quoteRequest.serviceType
                : "Maintenance service";
        },
        locationFoundItems() {
            return this.locationEntries;
        },
        servicesCount() {
            var count = this.leafFilteredRepairItemsTree.length;
            //if (this.hasMaintenanceService) {
            //    count++;
            //}
            return count;
        },
        serviceQuoteTypes() {
            return [
                //quoteTypes.smcServiceLabel, 
                //quoteTypes.igsServiceLabel, 
                //quoteTypes.wbcServiceLabel, 
                //quoteTypes.igsPolicyLabel
            ];
        },
        hasMaintenanceService() {
            let hasMaintenanceService = false;
            var vm = this;
            let includes = _.some(this.serviceQuoteTypes, function(qt) {
                return vm.quoteType.includes(qt);
            });
            if (includes) {
                hasMaintenanceService = true;
            }
            else {
                var serviceItem = _.find(this.quoteRequest.items, ["name", "Maintenance Service"]);
                if (serviceItem !== undefined) {
                    hasMaintenanceService = true;
                }
            }
            return hasMaintenanceService;
        },
        google: gmapApi,
    },
    watch: {
        vehicleProp(val) {
            if (val) {
                this.$store.dispatch("quoteRequestCreate/setQuoteRequestVehicle", val);
            }
        },
        'quoteRequest.applicationUser.email': function () {
            if (this.quoteRequest && this.quoteRequest.applicationUser) {
                this.quoteRequest.applicationUser.email = _.trim(this.quoteRequest.applicationUser.email);
            }
        },
        locationSearchText: async function (newVal) {
            if (!this.autocompleteSessionToken) {
                this.autocompleteSessionToken = placeService.getAutocompleteSessionToken();
            }
            var vm = this;
            // If less than 3 chars typed, do not search
            if (!newVal || newVal.length <= 3) return;

            await placeService
                .getSuggestions(newVal, this.autocompleteSessionToken)
                .then(function (res) {
                    vm.locationEntries = res;
                })
                .catch(function (err) {
                    console.error(err);
                });
        },
        showWorkshopList(val) {
            if (!val) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.generateBounds();
                        this.calculateWorkshopMappings();
                    }, 1000);
                });
            }
        },
        workshopsSelected(val) {
            if (!this.workshopFilterLoading) {
                if (!Array.isArray(val)) {
                    val = [val];
                }
                let selectedWorkshops = this.$store.getters["quoteRequestCreate/getSelectedWorkshopMappings"];

                if (val) {
                    val.forEach(selection => {
                        const workshopToAdd = this.organizationWorkshopList[selection];
        
                        // Ensure the workshop isn't already in selectedWorkshops
                        if (!selectedWorkshops.some(w => w.workshopId === workshopToAdd.workshopId)) {
                            selectedWorkshops.push(workshopToAdd);
                        }
                    });
                }

                // Remove workshops in filteredOrganizationWorkshopList but not in val
                const indexesToKeep = new Set(val); // Convert val to a Set for faster lookup

                selectedWorkshops = selectedWorkshops.filter(workshop => {
                    // Find the index of the workshop in filteredOrganizationWorkshopList
                    const indexInFilteredList = this.filteredOrganizationWorkshopList.findIndex(
                        w => w.workshopId === workshop.workshopId
                    );
        
                    // Keep the workshop if it's not in filteredOrganizationWorkshopList
                    // or its index is in val
                    return indexInFilteredList === -1 || indexesToKeep.has(indexInFilteredList);
                });

                this.$store.dispatch("quoteRequestCreate/setSelectedWorkshops", selectedWorkshops);
            }
        },
        workshopFilter() {
            this.workshopFilterLoading = true;
            this.workshopsSelected = [];

            var selectedWorkshops = this.$store.getters["quoteRequestCreate/getSelectedWorkshopMappings"];
            console.log("selectedWorkshops: ", selectedWorkshops);
            
            if (selectedWorkshops) {
                selectedWorkshops.forEach(selectedWorkshop => {
                    var index = _.findIndex(this.organizationWorkshopList, ["workshopId", selectedWorkshop.workshopId]);
                    if (index !== -1) {
                        this.workshopsSelected.push(index);
                    }
                });
            }
            this.workshopFilterLoading = false;
        },
        quoteRequest: {
            deep: true,
            handler(val) {
                this.$store.dispatch("quoteRequestCreate/setQuoteRequest", val);
            }
        },
        place(val) {
            this.$store.dispatch("quoteRequestCreate/setPlace", val);
        },
        currentStep(val) {
            if (val == 3) {
                if (!this.place && this.quoteRequest.address) {
                    this.place = {
                        address: this.quoteRequest.address
                    };
                    this.centerPosition.lat = this.place.address.lat;
                    this.centerPosition.lng = this.place.address.lng;
                    this.zoom = 18;
                    this.$nextTick(() => {
                        this.calculateWorkshopMappings();
                    });
                }
            }
        }
    },
    async created() {
        var vm = this;

        if (this.isAuthenticated) {
            if (!this.$store.getters["user/isLoaded"]) {
                await this.$store.dispatch("user/loadUser");
            }
        }

        var radiusDistances = process.env.VUE_APP_ADDRESS_RADIUS_DISTANCES.split(";");
        _.each(radiusDistances, function (radiusDistance) {
            var parsedDistance = parseFloat(radiusDistance);
            if (!isNaN(parsedDistance)) {
                vm.radiusDistances.push({
                    text: `${radiusDistance} KM`,
                    value: parsedDistance * 1000
                });
            }
        });

        var place = this.$store.getters["quoteRequestCreate/getPlace"];
        if (place) {
            this.place = place;
            //this.centerPosition.lat = typeof this.place.geometry.location.lat === "function" ? this.place.geometry.location.lat() : this.place.geometry.location.lat;
            //this.centerPosition.lng = typeof this.place.geometry.location.lng === "function" ? this.place.geometry.location.lng() : this.place.geometry.location.lng;
            this.centerPosition.lat = this.place.address.lat;
            this.centerPosition.lng = this.place.address.lng;
            this.zoom = 12;
            this.locationSearchText = addressHelper.stringifyAddress(place.address)
            this.autocompleteLocationModel = {
                id: "",
                value: this.locationSearchText
            };
        }
        else if (this.isOrganization) {
            var orgAddress = undefined;
            if (this.organization.organizationBranches.length > 0 && this.organization.organizationBranches[0].address) {
                orgAddress = _.clone(this.organization.organizationBranches[0].address);
            }
            else if (this.organization.address) {
                orgAddress = _.clone(this.organization.address);
            }
            if (orgAddress) {
                orgAddress.id = undefined;
                this.quoteRequest.address = orgAddress;
                this.autocompleteLocationModel = {
                    id: "",
                    value: addressHelper.stringifyAddress(orgAddress)
                };
            }
        }
        else if (this.isAuthenticated && this.storeUser.businessAddress) {
            var businessAddress = _.clone(this.storeUser.businessAddress);
            businessAddress.id = undefined;
            this.quoteRequest.address = businessAddress;
        }

        if (this.vehicleProp != undefined) {
            this.$store.dispatch("quoteRequestCreate/setQuoteRequestVehicle", this.vehicleProp);
            if (this.isAuthenticated) {
                var user = _.clone(this.storeUser);
                if (user.businessAddressString) {
                    user.businessAddress = addressHelper.objectifyAddress(user.businessAddressString);
                }

                this.$store.dispatch("quoteRequestCreate/setQuoteRequestUser", user);
                if (user.businessName) {
                    this.isBusiness = true;
                }
            }
        }

        if (this.isOrganization) {
            this.quoteRequest.serviceReminder = false;
            if (this.showWorkshopList === undefined) {
                this.showWorkshopList = true;
            }
            var selectedWorkshops = this.$store.getters["quoteRequestCreate/getSelectedWorkshopMappings"];
            if (selectedWorkshops) {
                selectedWorkshops.forEach(selectedWorkshop => {
                    var index = _.findIndex(this.organizationWorkshopList, ["workshopId", selectedWorkshop.workshopId]);
                    if (index !== -1) {
                        this.workshopsSelected.push(index);
                    }
                });
            }
        }

        var startingStep = this.currentStep;
        this.$store.dispatch("quoteRequestCreate/setCurrentStep", 1);
        if (startingStep !== 1) {
            setTimeout(() => {
                this.$store.dispatch("quoteRequestCreate/setCurrentStep", startingStep);
            }, 200);
        }
    },
    methods: {
        async initialize() {
            if (this.quoteTypeProp != undefined) {
                let qt = this.quoteTypeProp;
                if (this.quoteRequestTypeProp) {
                    qt = `${this.quoteRequestTypeProp} - ${qt}`;
                }
                this.$store.dispatch("quoteRequestCreate/setQuoteType", qt);
                if (this.quoteTypeProp !== quoteTypes.igsPolicyLabel) {
                    await this.$store.dispatch("quoteRequestCreate/loadRepairItemsTreeModel", this.quoteTypeProp);
                }
                await this.$store.dispatch("quoteRequestCreate/loadRegions");
                await this.$store.dispatch("quoteRequestCreate/loadWorkshopAddresses");
            }
        },
        async submit(cancelDuplicate, forceDuplicate) {
            // all validation and captcha passed / hide contact dialog
            var vm = this;
            this.validationMessages = [];
            this.$store.dispatch("contactSpeedDial/clear");

            this.submitting = true;
            this.quoteRequest.vehicleMileage = formFunctions.toNumber(
                this.quoteRequest.vehicleMileage
            );
            this.quoteRequest.items = this.leafFilteredRepairItemsTree;
            //if (_.some(this.serviceQuoteTypes, function(qt) {
            //    return vm.quoteType.includes(qt);
            //})) {
            //    this.quoteRequest.items.unshift({ name: this.annualServiceType });
            //}

            this.quoteRequest.quoteRequestWorkshopMappings = [];
            if (this.showWorkshopList) {
                this.allQuoteRequestWorkshopMappings.forEach(mapping => {
                    vm.quoteRequest.quoteRequestWorkshopMappings.push({
                        workshopId: mapping.workshopId
                    });
                });
            }
            else {
                _.forEach(this.quoteRequestWorkshopMappings, function (mapping) {
                    vm.quoteRequest.quoteRequestWorkshopMappings.push({
                        workshopId: mapping.workshopId
                    });
                });
                _.forEach(this.extraQuoteRequestWorkshopMappings, function (mapping) {
                    let intersect = _.intersection(vm.quoteRequest.regionIds, mapping.regionIds);
                    if (intersect.length > 0) {
                        vm.quoteRequest.quoteRequestWorkshopMappings.push({
                            workshopId: mapping.workshopId
                        });
                    }
                });
            }

            if (this.quoteRequest.applicationUser.phoneWithoutPrefix) {
                this.quoteRequest.applicationUser.phone = userHelper.addPhonePrefix(this.quoteRequest.applicationUser.phoneWithoutPrefix);
            }

            if (!this.isBusiness) {
                this.quoteRequest.applicationUser.businessName = null;
                this.quoteRequest.applicationUser.businessRegistrationNumber = null;
                this.quoteRequest.applicationUser.businessTaxNumber = null;
                this.quoteRequest.applicationUser.businessAddress = null;
            }

            this.fiveSecondsPassed = false;
            setTimeout(() => {
                this.fiveSecondsPassed = true;
            }, 2000);

            this.quoteRequest.quoteType = this.quoteType;
            this.quoteRequest.cancelDuplicate = cancelDuplicate == true;
            this.quoteRequest.forceDuplicate = forceDuplicate == true;

            var result = await quoteRequestService.createQuoteRequest(
                this.quoteRequest
            );

            if (result) {
                this.submitDialogShow = false;
                if (result.status == 201) {

                    this.trackEvent("SubmitRequest", "QuoteRequest", this.quoteTypeProp);
                    this.reportConversion(result.data.reference);

                    await this.$store.dispatch("user/loadUser");

                    if (result.data.isFulfilled) {
                        this.$router.push({
                            name: "quotes",
                            params: {
                                quoteRequestIdProp: result.data.uniqueId,
                                emailAddressProp: this.quoteRequest.applicationUser.email
                            }
                        });
                    }
                    else {
                        this.submitted = true;
                        this.quoteRequestReference = result.data.reference;
                        //this.$store.dispatch("quoteRequestCreate/resetQuoteRequest");
                        //if (this.nakedRedirectQueued) {
                        //    this.$refs.nakedBanner.redirectNaked();
                        //}
                    }

                } else {
                    this.submitting = false;
                    _.mapKeys(result.data, function (value, key) {
                        value.forEach(val => {
                            vm.validationMessages.push(val);
                        });

                        // The duplicate validation check executes on the API only if there were no other validation messages.
                        if (key == "Duplicate") {
                            vm.duplicateValidation = true;
                            vm.duplicateValidationShow = true;
                        }
                        if (key == "DuplicateQuote") {
                            vm.duplicateQuoteValidation = true;
                            vm.duplicateValidationShow = true;
                        }
                    });
                }
            }
        },
        closeDialog() {
            this.$emit("resetForm", { resetQuoteRequest: true, skipConfirmation: true });
        },
        updateBusinessAddress(address) {
            this.quoteRequest.applicationUser.businessAddress = address;
        },
        resetForm(attr) {
            this.$emit("resetForm", attr);
        },
        reset() {
            this.quoteRequest.regionIds = [];
            this.quoteRequest.address.streetNumber = "";
            this.quoteRequest.address.streetName = "";
            this.quoteRequest.address.suburb = "";
            this.quoteRequest.address.town = "";
            this.quoteRequest.address.city = "";
            this.quoteRequest.address.province = "";
            this.quoteRequest.address.country = "";
            this.quoteRequest.address.postalCode = "";
        },
        async setPlace() {
            this.mapIsLoading = true;
            var placeDetails = await placeService.getPlace(
                this.autocompleteLocationModel.id,
                this.autocompleteSessionToken
            );

            this.reset();
            var vm = this;

            _.forEach(this.regions, function (el) {
                let currentPolygon = new vm.google.maps.Polygon({
                    path: el.coordinates,
                    geodesic: true
                });
                if (
                    vm.google.maps.geometry.poly.containsLocation(
                        placeDetails.geometry.location,
                        currentPolygon
                    )
                ) {
                    vm.quoteRequest.regionIds.push(el.id);
                }
            });

            this.$refs.createQuoteSearchAddress.blur();

            this.quoteRequest.address = placeDetails.address;
            this.place = placeDetails;
            this.centerPosition.lat = placeDetails.geometry.location.lat;
            this.centerPosition.lng = placeDetails.geometry.location.lng;
            this.mapIsLoading = false;

            //this.generateBounds();
            if (this.quoteRequest.travelDistance > 0) {
                this.generateBounds();
                this.calculateWorkshopMappings();
            }
            else {
                this.zoom = 18;
                setTimeout(() => {
                    this.generateBounds();
                    this.calculateWorkshopMappings();
                }, 1000);
            }
        },
        generateBounds() {
            if (this.place && this.quoteRequest.travelDistance > 0 && this.currentStep == 3) {
                // create a fako circle with the same bounds as the one actually 
                // on the map to automatically zoom the map to those bounds
                let circle = new this.google.maps.Circle({
                    center: new this.google.maps.LatLng({
                        //lat: typeof this.place.geometry.location.lat === "function" ? this.place.geometry.location.lat() : this.place.geometry.location.lat,
                        //lng: typeof this.place.geometry.location.lng === "function" ? this.place.geometry.location.lng() : this.place.geometry.location.lng
                        lat: this.place.address.lat,
                        lng: this.place.address.lng
                    }),
                    radius: this.quoteRequest.travelDistance * 1000
                });
                this.$refs.gmap.$mapObject.fitBounds(circle.getBounds());
            }
        },
        getWorkshopMarkerIcon(address) {
            let isSelected = false;

            var index = _.findIndex(this.quoteRequestWorkshopMappings, ["workshopId", address.workshopId]);
            if (index !== -1) {
                isSelected = true;
            }

            if (!isSelected) {
                var extraIndex = _.findIndex(this.extraQuoteRequestWorkshopMappings, ["workshopId", address.workshopId]);
                if (extraIndex !== -1) {
                    isSelected = true;
                }
            }

            return {
                url: isSelected ? require('@/assets/images/workshop-marker-green-v1.png') : require('@/assets/images/workshop-marker-v1.png'),
                scaledSize: {
                    width: this.markerIconWidth,
                    height: this.markerIconHeight
                }
            };
        },
        mapZoomChange(zoom) {
            switch (zoom) {
                case 22:
                case 21:
                case 20:
                    this.markerIconWidth = 54;
                    this.markerIconHeight = 86.4;
                    break;
                case 19:
                case 18:
                case 17:
                case 16:
                case 15:
                case 14:
                    this.markerIconWidth = 48;
                    this.markerIconHeight = 76.8;
                    break;
                case 12:
                case 11:
                case 10:
                case 9:
                    this.markerIconWidth = 42;
                    this.markerIconHeight = 67.2;
                    break;
                case 8:
                case 7:
                    this.markerIconWidth = 39;
                    this.markerIconHeight = 62.4;
                    break;
                case 6:
                case 5:
                    this.markerIconWidth = 36;
                    this.markerIconHeight = 57.6;
                    break;
                case 4:
                case 3:
                    this.markerIconWidth = 33;
                    this.markerIconHeight = 52.8;
                    break;
                case 2:
                case 1:
                    this.markerIconWidth = 30;
                    this.markerIconHeight = 48;
                    break;
            }
        },
        calculateWorkshopMappings() {
            if (this.place) {
                this.$store.dispatch("quoteRequestCreate/setQuoteRequestWorkshopMappings", []);
                var workshopMappings = this.getWorkshopMappings(this.quoteRequest.travelDistance);

                workshopMappings.forEach(address => {
                    if (!this.isOrganization || address.isOrganization) {
                        this.$store.dispatch("quoteRequestCreate/addQuoteRequestWorkshopMapping", address);
                        var extraIndex = _.findIndex(this.extraQuoteRequestWorkshopMappings, ["workshopId", address.workshopId])
                        if (extraIndex !== -1) {
                            this.$store.dispatch("quoteRequestCreate/toggleExtraQuoteRequestWorkshopMapping", address);
                        }
                    }
                });

                var maxWorkshopMappings = this.getWorkshopMappings(this.maxTravelDistance);
                this.maxWorkshopMappingsCount = maxWorkshopMappings.length
            }
        },
        getWorkshopMappings(travelDistance) {
            let mappings = [];
            if (this.place) {
                let searchArea = new this.google.maps.Circle({
                    center: new this.google.maps.LatLng({
                        //lat: typeof this.place.geometry.location.lat === "function" ? this.place.geometry.location.lat() : this.place.geometry.location.lat,
                        //lng: typeof this.place.geometry.location.lng === "function" ? this.place.geometry.location.lng() : this.place.geometry.location.lng
                        lat: this.place.address.lat,
                        lng: this.place.address.lng
                    }),
                    radius: travelDistance * 1000
                });

                var radius = searchArea.getRadius();
                this.workshopAddresses.forEach(address => {
                    var distance = this.google.maps.geometry.spherical.computeDistanceBetween(
                        {
                            lat: address.lat,
                            lng: address.lng
                        },
                        searchArea.getCenter());

                    if (distance <= radius) {
                        mappings.push(address);
                    }
                });
            }
            return mappings;
        },
        toggleExtraWorkshopAddress(address) {
            if (this.place) {
                let workshops = this.$store.getters["quoteRequestCreate/getQuoteRequestWorkshopMappings"];

                var intersect = _.some(workshops, ["workshopId", address.workshopId]);
                if (!intersect) {
                    this.$store.dispatch("quoteRequestCreate/toggleExtraQuoteRequestWorkshopMapping", address);
                }
                else {
                    this.$store.dispatch("quoteRequestCreate/toggleQuoteRequestWorkshopMapping", address);
                }
            }
        },
        toggleWorkshopMarkerHover(address) {
            if (this.isOrganization) {
                address.infoIsOpen = true;
            }
        },
        addAttachment() {
            if (!this.quoteRequest.attachments) {
                this.quoteRequest.attachments = [];
            }
            var index = 1;
            if (this.quoteRequest.attachments.length > 0) {
                index = _.last(this.quoteRequest.attachments).index;
                index++;
            }
            this.quoteRequest.attachments.push({
                index,
                file: undefined
            });
        },
        deleteAttachment(attachment) {
            var index = this.quoteRequest.attachments.findIndex(att => att.index == attachment.index);
            this.quoteRequest.attachments.splice(index, 1);
        },
        addCustomerReference() {
            if (!this.quoteRequest.quoteCustomerReferences) {
                this.quoteRequest.quoteCustomerReferences = [];
            }
            var index = 1;
            if (this.quoteRequest.quoteCustomerReferences.length > 0) {
                index = _.last(this.quoteRequest.quoteCustomerReferences).index;
                index++;
            }
            this.quoteRequest.quoteCustomerReferences.push({
                index,
                name: "",
                reference: ""
            });
        },
        addSalesExecutive() {
            if (!this.quoteRequest.quoteCustomerReferences) {
                this.quoteRequest.quoteCustomerReferences = [];
            }
            var index = 1;
            if (this.quoteRequest.quoteCustomerReferences.length > 0) {
                index = _.last(this.quoteRequest.quoteCustomerReferences).index;
                index++;
            }
            this.quoteRequest.quoteCustomerReferences.push({
                index,
                name: "Sales Executive",
                reference: ""
            });
        },
        deleteCustomerReference(customerReference) {
            var index = this.quoteRequest.quoteCustomerReferences.findIndex(cust => cust.index == customerReference.index);
            this.quoteRequest.quoteCustomerReferences.splice(index, 1);
        },
        removeRepairItem(repairItemId) {
            var index = this.quoteRequest.items.findIndex(item => item.id == repairItemId);
            this.quoteRequest.items.splice(index, 1);
        },
        //removeServiceIntervalItem(templateItem) {
        //    this.$store.dispatch("quoteRequestCreate/removeServiceIntervalTemplateItem", templateItem);
        //},
        //async loadServiceIntervalItems() {
        //    this.serviceIntervalLoading = true;
        //    await this.$store.dispatch("quoteRequestCreate/loadVehicleServiceInterval", true);
        //    this.serviceIntervalLoading = false;
        //},
        loadServiceIntervalItems(repairItem, intervalItems) {
            repairItem.templateItems = intervalItems;
        },
        treeItemClicked(treeItem) {
            if (treeItem.leaf) {
                var repairItemIndex = this.quoteRequest.items.indexOf(treeItem);
                if (repairItemIndex == -1) {
                    this.quoteRequest.items.push(_.clone(treeItem));
                } else {
                    this.removeRepairItem(treeItem.id);
                }
            }
        },
        async validateCurrentStepForm() {
            let returnValue = false;

            if (this.currentStep == 1) {
                returnValue = await this.$refs.step1formValidationObserver.validate();

                for (let i = 0; i < Object.keys(this.$refs.step1form.$children[0].$children).length; i++) {
                    if (
                        this.$refs.step1form.$children[0].$children[i].errors !== undefined &&
                        this.$refs.step1form.$children[0].$children[i].errors.length > 0
                    ) {
                        this.$vuetify.goTo(
                            this.$refs.step1form.$children[0].$children[i],
                            this.gotoOptions
                        );
                        break;
                    }
                }
            } else if (this.currentStep == 2) {
                this.noServicesError = false;
                returnValue = await this.$refs.step2formValidationObserver.validate();
                let foundError = false;

                for (let i = 0; i < Object.keys(this.$refs.repairItemList.$children).length; i++) {
                    if (foundError == true) {
                        break;
                    }

                    for (let y = 0; y < Object.keys(this.$refs.repairItemList.$children[i].$children).length; y++) {
                        if (
                            this.$refs.repairItemList.$children[i].$children[y].errors !== undefined &&
                            this.$refs.repairItemList.$children[i].$children[y].errors.length > 0
                        ) {
                            this.$vuetify.goTo(
                                this.$refs.repairItemList.$children[i].$children[y],
                                this.gotoOptions
                            );
                            foundError = true;
                            break;
                        }
                    }
                }

                if (this.servicesCount == 0) {
                    returnValue = false;
                    this.noServicesError = true;
                }
            } else if (this.currentStep == 3) {
                returnValue = await this.$refs.step3formValidationObserver.validate();
                this.noDatesError = false;
                if (!this.showWorkshopList) {
                    if (
                        this.allQuoteRequestWorkshopMappings.length == 0 ||
                        (this.allQuoteRequestWorkshopMappings.length < this.minWorkshopMappings && this.allQuoteRequestWorkshopMappings.length < this.maxWorkshopMappingsCount) &&
                        !this.isOrganization
                    ) {
                        returnValue = false;
                        this.mapNoWorkshopsShow = true;
                    }
                    for (let i = 0; i < Object.keys(this.$refs.step3form.$children[0].$children).length; i++) {
                        if (
                            this.$refs.step3form.$children[0].$children[i].errors !== undefined &&
                            this.$refs.step3form.$children[0].$children[i].errors.length > 0
                        ) {
                            this.$vuetify.goTo(
                                this.$refs.step3form.$children[0].$children[i],
                                this.gotoOptions
                            );
                            break;
                        }
                    }
                }
                else {
                    if (this.workshopsSelected == null || this.workshopsSelected == undefined || this.workshopsSelected.length == 0) {
                        returnValue = false;
                    }
                }
                if (!this.quoteRequest.datesRequested || this.quoteRequest.datesRequested.length == 0) {
                    returnValue = false;
                    this.noDatesError = true;
                }
            } else if (this.currentStep == 4) {
                returnValue = await this.$refs.step4formValidationObserver.validate();

                for (let i = 0; i < Object.keys(this.$refs.step4form.$children[0].$children).length; i++) {
                    if (
                        this.$refs.step4form.$children[0].$children[i].errors !== undefined &&
                        this.$refs.step4form.$children[0].$children[i].errors.length > 0
                    ) {
                        this.$vuetify.goTo(
                            this.$refs.step4form.$children[0].$children[i],
                            this.gotoOptions
                        );
                        break;
                    }
                }
            }

            return returnValue;
        },
        stepChanged(event) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.$vuetify.goTo("#vehicle-card");
            }
            this.$store.dispatch("quoteRequestCreate/setCurrentStep", parseInt(event));
        },
        async stepForward(val) {
            this.stepLoading = true;
            if (await this.validateCurrentStepForm()) {
                //if (val == 2 && (this.quoteType == this.quoteTypes.serviceLabel || this.quoteType == this.quoteTypes.policyLabel)) {
                //    await this.$store.dispatch("quoteRequestCreate/loadVehicleServiceInterval");
                //}
                if (val == 3 && this.isOrganization) {
                    let note = this.quoteRequest.vehicleName;
                    let noteQuoteRequestItems = [];
                    this.quoteRequest.items.forEach(qri => {
                        if (qri.queryAnswer) {
                            noteQuoteRequestItems.push(qri.queryAnswer);
                        }
                        else {
                            noteQuoteRequestItems.push(qri.name);
                        }
                    });
                    note = `${note} - ${_.join(noteQuoteRequestItems, ', ')}`
                    note = `${note} - Sales Person is `;

                    if (this.quoteType == quoteTypes.wbcInspectionLabel) {
                        note = `${note}\n\nPRE-QUOTE INSPECTION NEEDED: This Request Is Sent Directly To Your Workshop. Physical Inspection Is Required. Provide Quote After Inspection.`;
                    }
                    this.quoteRequest.notes = note;
                }
                this.goStep(val);
            }
            this.stepLoading = false;
        },
        goStep(val) {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.$nextTick(() => {
                    this.$vuetify.goTo("#vehicle-card");
                });
            }
            else {
                this.$vuetify.goTo("#quote-request-stepper-wrapper", {
                    duration: 1000
                });
            }
            this.$store.dispatch("quoteRequestCreate/setCurrentStep", val);
        },
        async checkRecapcha() {
            if (await this.validateCurrentStepForm()) {
                this.$emit('validateRecaptcha');
            }
        },

        redirectNaked() {
            this.trackEvent("Redirect", "NakedInsurance", this.analyticsLabelProp);
            window.open(process.env.VUE_APP_NAKED_LINK, "_blank");
        },
        weekDays: val => ![0, 6].includes(new Date(val).getDay()),
    }
}