<template>
  <v-container>
    <v-card outlined>
      <v-card-title class="mb-3 primary white--text py-6">
        <span class="mx-auto"> Current Vehicle Details </span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="grow">
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Year</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.year"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Brand</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.brand"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Range</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.range"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Model</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.model"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Fuel Type</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.fuelType"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="my-0 mb-n2">
              <v-col class="py-0" cols="3">
                <v-subheader>Transmission Type</v-subheader>
              </v-col>
              <v-col class="py-0">
                <v-text-field
                  hide-details
                  class="mb-0"
                  :value="quoteRequestProp.vehicle.transmissionType"
                  readonly
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col class="shrink">
            <div class="smc-card-avatar" style="width: 320px">
              <img
                :src="quoteRequestProp.vehicle.imageUrl"
                class="smc-search-result-image"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-6" flat v-if="loading">
      <v-skeleton-loader type="table-heading"></v-skeleton-loader>
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="table-heading"></v-skeleton-loader>
      <v-skeleton-loader type="image"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
      <v-skeleton-loader type="paragraph"></v-skeleton-loader>
    </v-card>
    <v-tabs v-else fixed-tabs background-color="primary" class="mt-6">
      <v-tab v-for="(item, index) in quoteRequestItems" :key="index">
        {{ item.name }}
      </v-tab>
      <v-tab>
          <v-icon> mdi-magnify </v-icon>
            Search
      </v-tab>

      <v-tab-item v-for="(item, index) in quoteRequestItems" :key="index">
        <quote-price-item-lookup
          :quoteRequestItemProp="item"
          :quoteRequestProp="quoteRequestProp"
        ></quote-price-item-lookup>
      </v-tab-item>
      <v-tab-item>
        <quote-price-item-lookup
        :quoteRequestSearchMode="true"
         :quoteRequestProp="quoteRequestProp"
        >
        </quote-price-item-lookup>
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import _ from "lodash";
import quotePriceItemLookup from "@/components/quote-price-item-lookup";
import { quoteRequestService } from "@/services";

export default {
  name: "quote-price-lookup",
  components: {
    quotePriceItemLookup,
  },
  props: {
    quoteRequestProp: Object,
  },
  data: () => ({
    loading: false,
    quoteRequestItems: [],
  }),
  async created() {
    await this.loadQuoteRequestItem();
  },
  methods: {
    async loadQuoteRequestItem() {
      this.loading = true;
      let quoteRequest = await quoteRequestService.getQuoteRequest(
        this.quoteRequestProp.uniqueId
      );
      if (quoteRequest) {
        this.quoteRequestItems = quoteRequest.items;
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>