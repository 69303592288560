const smcServiceLabel = "SMC_Service";           // 0
const smcRepairLabel = "SMC_Repair";             // 1
const igsServiceLabel = "IGS_Service";           // 2
const igsRepairLabel = "IGS_Repair";             // 3
const igsPolicyLabel = "IGS_Policy";             // 4
const wbcServiceLabel = "WBC_Service";           // 5
const wbcRepairLabel = "WBC_Repair";             // 6
const wbcInspectionLabel = "WBC_Inspection";     // 7
const wbcWheelsTyresLabel = "WBC_WheelsTyres";   // 8
const wbcWindscreenLabel = "WBC_Windscreen";     // 9
const wbcBodyExternalLabel = "WBC_BodyExternal"; // 10
const wbcDekraLabel = "WBC_Dekra";               // 11

export const quoteTypes = {
  smcServiceLabel,
  smcRepairLabel,
  igsServiceLabel,
  igsRepairLabel,
  igsPolicyLabel,
  wbcServiceLabel,
  wbcRepairLabel,
  wbcInspectionLabel,
  wbcWheelsTyresLabel,
  wbcWindscreenLabel,
  wbcBodyExternalLabel,
  wbcDekraLabel
};

export const authStatuses = {
  success: 0,
  failed: 1,
  emailConfirmationRequired: 2,
  newPasswordRequired: 3,
  lockedOut: 4,
  notAllowed: 5,
  requiresTwoFactor: 6
};

export const amendmentTypes = {
  added: 0,
  modified: 1,
  removed: 2
};

export const amendmentStatuses = {
  pending: 0,
  approved: 1,
  declined: 2
};

export const innovationPolicyStatuses = {
  active: { value: "ACTIVE POLICY", level: "", title: "", message: "" },
  expired: { value: "EXPIRED POLICY", level: "", title: "", message: "" },
  cancelled: { value: "CANCELLED POLICY", level: "", title: "", message: "" },
  suspened: { value: "SUSPEND POLICY", level: "warning", title: "Policy suspended", message: "Please note that the expense might not be covered." },
  inactive: { value: "INACTIVE POLICY", level: "info", title: "Policy inactive", message: "You may only claim on this policy after three (3) consecutive successful debit orders." },
  hold: { value: "HOLD POLICY", level: "warning", title: "Policy on hold", message: "Please note that the expense might not be covered." },
  cancellationProgress: { value: "CANCELLATION IN PROGRESS", level: "warning", title: "Cancellation in progress", message: "Please note that the expense might not be covered." },
  cancellationPendingRecovery: { value: "CANCELLATION PENDING RECOVERY", level: "warning", title: "Cancellation pending recovery", message: "Please note that the expense might not be covered." },
  cancellationPendingCollections: { value: "CANCELLATION PENDING COLLECTIONS", level: "warning", title: "Cancellation pending collection", message: "Please note that the expense might not be covered." },
  cancellationRequested: { value: "CANCELLATION REQUESTED", level: "warning", title: "Cancellation requested", message: "Please note that the expense might not be covered." },
};

export const quoteRequestStatuses = {
  submitted: {
    label: "Submitted",
    value: "Submitted",
    id: 0
  },
  cancelled: {
    label: "Cancelled",
    value: "Cancelled",
    id: 1
  },
  completed: {
    label: "Completed",
    value: "Completed",
    id: 2
  }
};

export const quoteStatuses = {
  created: {
    label: "Created",
    value: "Created",
    id: null
  },
  pendingApproval: {
    label: "Pending Approval",
    value: "PendingApproval",
    id: 0
  },
  booked: {
    label: "Booked",
    value: "Booked",
    id: 1
  },
  inProgress: {
    label: "In Progress",
    value: "InProgress",
    id: 2
  },
  amendmentNotSubmitted: {
    label: "Amendment Not Submitted",
    value: "AmendmentNotSubmitted",
    id: null
  },
  amendmentPending: {
    label: "Amendment Pending",
    value: "AmendmentPending",
    id: 3
  },
  proForma: {
    label: "Draft Invoice",
    value: "ProForma",
    id: 4
  },
  invoiced: {
    label: "Invoiced",
    value: "Invoiced",
    id: 5
  },
  completed: {
    label: "Completed",
    value: "Completed",
    id: 6
  },
  paid: {
    label: "Paid",
    value: "Paid",
    id: 7
  },
  checkout: {
    label: "Checkout",
    value: "Checkout",
    id: 8
  },
  updated: {
    label: "Updated",
    value: "Updated",
    id: 9
  },
  draftRequest: {
    label: "Draft Pre-Quote",
    value: "draftRequest",
    id: null
  },
  request: {
    label: "Pre-Quote",
    value: "Request",
    id: 10
  },
  draft: {
    label: "Draft",
    value: "Draft",
    id: 11
  }
};

export const recentActionSources = [
  { label: "Vehicle", color: "#373736", text: "white" },
  { label: "Quote Request", color: "#ED3237", text: "white" },
  { label: "Quote", color: "#FFC107", text: "black" },
  { label: "Booked", color: "#FF9800", text: "black" },
  { label: "Checked In", color: "#4CAF50", text: "white" },
  { label: "Amendment", color: "#01A6FF", text: "white" },
  { label: "Invoice", color: "#02275A", text: "white" },
];

export const recentActionTypes = [
  "Created",
  "Updated",
  "Submitted",
  "Received"
];