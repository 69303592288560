import _ from "lodash";
import { userService } from "@/services";

export const notifications = {
    namespaced: true,
    state: {
        notifications: []
    },
    actions: {
        async loadNotifications({ commit }) {
            var notifications = await userService.loadNotifications();
            commit("loadNotifications", notifications);
        },
        setNotificationRead({ commit }, notification) {
            commit("setNotificationRead", notification);
        },
        clearNotifications({ commit }) {
            commit("clearNotifications");
        },
    },
    mutations: {
        loadNotifications(state, notifications) {
            notifications.forEach(notification => {
                var existingIndex = state.notifications.findIndex(n => n.id === notification.id);
                if (existingIndex === -1) {
                    state.notifications.push(notification);
                } 
            });
        },
        setNotificationRead(state, notification) {
            if (notification) {
                let index = state.notifications.findIndex(n => n.id == notification.id);
                console.log("index: ", index);
                if (index !== -1) {
                    state.notifications[index].isRead = true;
                }
            }
        },
        clearNotifications(state) {
            state.notifications = [];
        }
    },
    getters: {
        getNotifications: state => organizationBranchId => {
            let lastFive = 
            _.filter(_.takeRight(_.filter(state.notifications, ["organizationBranchId", organizationBranchId]), 5), function(n) {
                return n.isRead !== true;
            });
            return lastFive;
        }
    }
};
