import _ from "lodash";
import { userHelper } from "@/helpers";
import { userService } from "@/services";
import User from '@/models/user';

export const user = {
  namespaced: true,
  state: {
    authUserId: undefined,
    user: undefined,
    organization: undefined,
    organizationBranchOptions: undefined,
    innovationPolicy: undefined,
    accessToken: undefined,
    refreshToken: undefined
  },
  mutations: {
    setAuthUserId(state, id) {
      state.authUserId = id;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setInnovationPolicy(state, payload) {
      state.innovationPolicy = payload;
    },
    setOrganization(state, payload) {
      state.organization = payload;
    },
    setOrganizationBranchOptions(state, payload) {
      state.organizationBranchOptions = payload;
    },
    setToken(state, payload) {
      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
    },
    removeUser(state) {
      state.authUserId = undefined;
      state.user = undefined;
      state.organization = undefined;
      state.organizationBranchOptions = undefined;
      state.innovationPolicy = undefined;
      state.accessToken = undefined;
      state.refreshToken = undefined;
    }
  },
  actions: {
    setAuthUserId({commit}, authUserId) {
      commit("setAuthUserId", authUserId);
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    setInnovationPolicy({ commit }, innovationPolicy) {
      commit("setInnovationPolicy", innovationPolicy);
    },
    async loadOrganizationBranch({commit}) {
      var result = await userService.loadUserOrganization();
      if (result.status == 200) {
        let branches = result.data.organizationBranches;
        let options = _.map(branches, function(ob) {
          return {
            organizationBranchId: ob.id,
            organizationBranchName: ob.branchName
          }
        });
        commit("setOrganizationBranchOptions", options);
        var selectedBranch = _.find(branches, ["id", result.data.authUser.organizationBranchId]);
        if (selectedBranch) {
          let organization = result.data;
          organization.organizationBranches = [selectedBranch];
          commit("setOrganization", organization);
        }
      }
    },
    setOrganization({ commit }, organization) {
      commit("setOrganization", organization);
    },
    setOrganizationBranchOptions({ commit }, organizationBranchOptions) {
      commit("setOrganizationBranchOptions", organizationBranchOptions);
    },
    setToken({ commit }, token) {
      commit("setToken", token);
    },
    removeUser({ commit }) {
      commit("removeUser");
    },
    async loadUser({ commit }) {
      var result = await userService.getUser();
      if (result.status == 200) {
        var user = result.data;
        user.isLoaded = true;
        commit('setUser', user);
      }
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.user != undefined;
    },
    hasInnovationPolicy: state => {
      return state.innovationPolicy && state.innovationPolicy.hasInnovationPolicy;
    },
    getInnovationPolicyList: state => {
      return _.split(state.innovationPolicy.innovationPolicyList, ',');
    },
    isLoaded: state => {
      return state.user != undefined && state.user.isLoaded;
    },
    getUserId: state => {
      return state.authUserId;
    },
    getEmail: state => {
      return state.user.email;
    },
    getPhoneNumber: state => {
      return state.user.phoneNumber;
    },
    getPhoneNumberWithoutPrefix: state => {
      return userHelper.removePhonePrefix(state.user.phoneNumber);
    },
    getFirstName: state => {
      return state.user.firstName;
    },
    getSurname: state => {
      return state.user.lastName;
    },
    getBusinessName: state => {
      return state.user.businessName;
    },
    getBusinessRegistrationNumber: state => {
      return state.user.businessRegistrationNumber;
    },
    getBusinessTaxNumber: state => {
      return state.user.businessTaxNumber;
    },
    getBusinessAddressString: state => {
      return state.user.businessAddress;
    },
    hasBusinessDetails: state => {
      return state.user.businessName &&
        state.user.businessName != "" &&
        state.user.businessName != null &&
        state.user.businessName != undefined;
    },
    hasBusinessAddress: state => {
      return state.user.businessAddress &&
        state.user.businessAddress != null &&
        state.user.businessAddress != undefined;
    },
    isOrganization: state => {
      return state.organization !== undefined
    },
    getOrganization: state => {
      return state.organization;
    },
    getOrganizationBranchOptions: state => {
      return state.organizationBranchOptions;
    },
    isOrganizationAdmin: state => {
      let organizationUsers = _.split(process.env.VUE_APP_WBC_ADMIN_USERS, '|');
      return organizationUsers.includes(state.user.email.toLowerCase());
    },
    getUserModel: state => {

      // phone number can be undefined for google users
      var phoneNumber = state.user.phone;

      return new User(
        state.user.id,
        state.user.firstName,
        state.user.lastName,
        state.user.email,
        phoneNumber ? phoneNumber : "",
        phoneNumber ? userHelper.removePhonePrefix(phoneNumber) : "",
        state.user.businessName,
        state.user.businessRegistrationNumber,
        state.user.businessTaxNumber,
        state.user.businessAddress,
        state.organization
      );
    },
    getAccessToken: state => state.accessToken,
    getRefreshToken: state => state.refreshToken
  }
};
