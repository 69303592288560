<template>
  <v-card color="white" class="smc-card-dialog" :class="cardStatusClass">
    <div></div>
    <v-card-title
      class="smc-card-dialog-title-inset white--text"
      :class="getQuoteRequestDisplayColor(quoteRequestProp)"
    >
      <span class="mx-auto">
        <text-highlight
          :queries="searchQueryProp"
          highlightClass="search-highlight"
        >
          {{ quoteRequestProp.reference }}
        </text-highlight>
      </span>
    </v-card-title>
    <v-card-subtitle class="smc-card-dialog-subtitle-inset">
      <span class="font-weight-medium">
        {{ dateHelper.formatDateLong(quoteRequestProp.dateCreated) }}
      </span>
    </v-card-subtitle>
    <span
      class="quote-status-label"
      v-if="acceptedQuote || isInspection || isCancelled"
    >
      <v-chip
        v-if="isInspection"
        label
        :color="getQuoteRequestDisplayColor(quoteRequestProp)"
        class="mx-1"
      >
        Inspect to Quote
      </v-chip>
      <v-chip v-if="isCancelled" label color="grey" class="mx-1 white--text">
        Cancelled
      </v-chip>
      <em v-if="isCancelled && quoteRequestProp.quoteRequestStatusUsername">
        by {{ quoteRequestProp.quoteRequestStatusUsername }}
      </em>
      <v-chip
        v-if="acceptedQuote"
        label
        :color="getQuoteRequestDisplayColor(quoteRequestProp)"
        class="mx-1"
      >
        {{ quoteStatusLabel }}
      </v-chip>
      <v-chip v-if="isCompleted" label color="success" class="mx-1 white--text">
        Completed
      </v-chip>
      <em v-if="isCompleted && quoteRequestProp.quoteRequestStatusUsername">
        by {{ quoteRequestProp.quoteRequestStatusUsername }}
      </em>
    </span>
    <v-card-text>
      <v-row class="mt-4">
        <v-col>
          <div class="smc-card-avatar">
            <img
              :src="quoteRequestProp.vehicle.imageUrl"
              class="smc-search-result-image"
            />
          </div>
          <v-row>
            <v-col>
              <v-dialog v-model="vehicleDetailsShow" width="800">
                <v-toolbar color="primary" dark height="48">
                  Vehicle Details
                </v-toolbar>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    secondary
                    rounded
                    outlined
                    color="info"
                    class="px-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Vehicle Details
                  </v-btn>
                </template>
                <v-card>
                  <vehicle-details
                    :vehicleMappingProp="{ vehicle: quoteRequestProp.vehicle }"
                    :hideImageProp="true"
                  ></vehicle-details>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      rounded
                      class="px-4"
                      @click="vehicleDetailsShow = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="text-align: left">
          <v-label>
            <strong>
              <text-highlight
                :queries="searchQueryProp"
                highlightClass="search-highlight"
              >
                {{ quoteRequestProp.vehicleName }}
              </text-highlight>
            </strong>
          </v-label>
          <br />
          <v-label>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ quoteRequestProp.vehicle.year }}
            </text-highlight>
          </v-label>
          <br />
          <v-label>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ quoteRequestProp.vehicle.brand }}
            </text-highlight>
          </v-label>
          <br />
          <v-label>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ quoteRequestProp.vehicle.range }}
            </text-highlight>
          </v-label>
          <br />
          <v-label>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ quoteRequestProp.vehicle.model }}
            </text-highlight>
          </v-label>
          <br />
          <v-label>
            <strong>Mileage:</strong>
            {{ quoteRequestProp.vehicleMileage | numeral("0,0") }} KM
          </v-label>
        </v-col>
        <v-col v-if="isOrganization" class="pa-0">
          <v-card outlined class="mb-2">
            <v-simple-table class="text-left" dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <th colspan="2">Requested by</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <text-highlight
                        :queries="searchQueryProp"
                        highlightClass="search-highlight"
                      >
                        {{
                          `${quoteRequestProp.applicationUser.firstName} ${quoteRequestProp.applicationUser.lastName}`
                        }}
                      </text-highlight>
                      <br />
                      <text-highlight
                        :queries="searchQueryProp"
                        highlightClass="search-highlight"
                      >
                        {{ quoteRequestProp.applicationUser.email }}
                      </text-highlight>
                    </td>
                  </tr>
                  <tr>
                    <th colspan="2">Date Requested</th>
                  </tr>
                  <tr>
                    <td colspan="2">
                      {{
                        dateHelper.formatDateHours(quoteRequestProp.dateCreated)
                      }}
                    </td>
                  </tr>
                  <tr v-if="!acceptedQuote">
                    <th colspan="2">Time Since Requested</th>
                  </tr>
                  <tr v-if="!acceptedQuote">
                    <td colspan="2">
                      {{ dateHelper.formatTimeSpan(timeSinceRequested) }}
                    </td>
                  </tr>
                  <tr v-if="acceptedQuote">
                    <th colspan="2">Date Quoted</th>
                  </tr>
                  <tr v-if="acceptedQuote">
                    <td colspan="2">
                      {{
                        dateHelper.formatDateHours(acceptedQuote.dateCreated)
                      }}
                    </td>
                  </tr>
                  <tr v-if="isInvoiced">
                    <th colspan="2">Job Turnaround Time</th>
                  </tr>
                  <tr v-if="isInvoiced">
                    <td colspan="2">
                      {{
                        dateHelper.formatTimeSpan(acceptedQuote.jobTurnaround)
                      }}
                    </td>
                  </tr>
                  <tr v-if="anyQuotes">
                    <th
                      class="white--text"
                      :class="orderAttached ? `green` : `red`"
                    >
                      Order Attached
                    </th>
                    <th
                      style="width: 56px"
                      :style="
                        orderAttached
                          ? 'border: 1px solid #4caf50;'
                          : 'border: 1px solid #f44336;'
                      "
                    >
                      <v-icon
                        v-if="orderAttached"
                        color="green"
                        class="float-right"
                      >
                        mdi-file-document-check
                      </v-icon>
                      <v-icon v-else class="float-right" color="red">
                        mdi-file-document-remove
                      </v-icon>
                    </th>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
        <v-col class="text-center">
          <div v-if="acceptedQuote" class="mb-6">
            <v-img
              class="pa-6 mx-auto"
              :src="acceptedQuote.workshop.logoUrl"
              :lazy-src="acceptedQuote.workshop.logoUrl"
              contain
              max-height="100"
              max-width="200"
            ></v-img>
            <h3>
              <text-highlight
                :queries="searchQueryProp"
                highlightClass="search-highlight"
              >
                {{ acceptedQuote.workshop.name }}
              </text-highlight>
            </h3>
          </div>
          <div
            v-else-if="quoteRequestProp.quoteRequestWorkshopMappings"
            class="mb-6"
          >
            <div
              v-if="quoteRequestProp.quoteRequestWorkshopMappings.length == 1"
            >
              <v-img
                class="pa-6 mx-auto"
                :src="
                  quoteRequestProp.quoteRequestWorkshopMappings[0].workshop
                    .logoUrl
                "
                :lazy-src="
                  quoteRequestProp.quoteRequestWorkshopMappings[0].workshop
                    .logoUrl
                "
                contain
                max-height="100"
                max-width="200"
              ></v-img>
              <h3>
                {{
                  quoteRequestProp.quoteRequestWorkshopMappings[0].workshop.name
                }}
              </h3>
            </div>
            <div v-else class="text-center">
              <v-dropdown
                v-if="isOrganization"
                :shown.sync="workshopMappingsIsOpen"
                :distance="6"
                :container="`#smc-app`"
                placement="bottom-end"
              >
                <v-btn color="primary" rounded>
                  Selected Workshops
                  <v-chip small class="ml-2 px-2">
                    {{ quoteRequestProp.quoteRequestWorkshopMappings.length }}
                  </v-chip>
                </v-btn>

                <template #popper>
                  <div class="workshop-mappings-list">
                    <v-card
                      color="white"
                      class="ma-6 pa-3 text-center"
                      v-for="(
                        mapping, index
                      ) in quoteRequestProp.quoteRequestWorkshopMappings"
                      :key="index"
                    >
                      <v-card-text>
                        <v-chip
                          color="dark"
                          outlined
                          small
                          style="position: absolute; right: 0; top: 0"
                        >
                          #{{ index + 1 }}
                        </v-chip>
                        <v-img
                          class="mx-auto"
                          :src="mapping.workshop.logoUrl"
                          :lazy-src="mapping.workshop.logoUrl"
                          contain
                          max-height="100"
                          max-width="200"
                        ></v-img>
                        <h3 class="mt-2">
                          {{ mapping.workshop.name }}
                        </h3>
                      </v-card-text>
                    </v-card>
                  </div>
                </template>
              </v-dropdown>
            </div>
          </div>
          <p class="title font-weight-bold" v-if="!acceptedQuote">
            Quotes:
            <span
              :class="`${getQuoteRequestDisplayColor(quoteRequestProp)}--text`"
            >
              {{ acceptedQuote ? 1 : quoteRequestProp.quotes.length }}
            </span>
          </p>
          <p v-if="acceptedQuote" class="title font-weight-bold">
            Price:
            <span
              style="white-space: nowrap"
              :class="`${getQuoteRequestDisplayColor(quoteRequestProp)}--text`"
            >
              {{ acceptedQuote.quoteTotalIncl | currency }}
            </span>
          </p>
        </v-col>
        <v-divider
          v-if="$vuetify.breakpoint.smAndUp"
          vertical
          role="presentation"
        />
        <v-col class="shrink">
          <v-btn
            block
            rounded
            class="mb-3"
            :color="getQuoteRequestDisplayColor(quoteRequestProp)"
            :to="`/quotes/${quoteRequestProp.uniqueId}`"
            :disabled="isCancelled"
          >
            View
          </v-btn>

          <v-btn
            v-if="isOrganization"
            block
            rounded
            class="mb-3"
            :color="getQuoteRequestDisplayColor(quoteRequestProp)"
            :disabled="isCancelled"
            @click="showPricesDialog = true"
          >
            View Prices
          </v-btn>

          <v-dialog
            v-if="enableCompleteButtonProp"
            v-model="completeJobShow"
            persistent
            scrollable
            transition="dialog-top-transition"
            content-class="smc-dialog"
            >
            <!--content-class="smc-dialog smc-dialog-wide"-->
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                outlined
                rounded
                color="dark"
                class="mb-3"
                v-bind="attrs"
                v-on="on"
              >
                Complete
              </v-btn>
            </template>

            <v-card class="smc-card-dialog" color="white">
              <v-card-title
                class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
              >
                <span class="mx-auto">CONFIRM</span>
              </v-card-title>
              <div class="smc-card-top-bar">
                <v-btn
                  icon
                  color="midGrey"
                  class="float-right"
                  height="32"
                  width="32"
                  @click="completeJobShow = false"
                >
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </div>

              <v-sheet class="smc-card-dialog-content-sheet">
                <v-card-text>
                  <P> Are you sure you want to complete this job? </P>
                </v-card-text>
              </v-sheet>
              <v-divider></v-divider>
              <v-card-text class="d-none">
                <iframe src="https://survey.zohopublic.com/zs/I3Clms" frameborder='0' style='height:700px;width:100%;' marginwidth='0' marginheight='0' scrolling='auto' allow='geolocation'></iframe>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  rounded
                  color="secondary"
                  outlined
                  @click="completeJobShow = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  rounded
                  color="primary"
                  @click="completeQuote"
                  :loading="loading"
                >
                  Submit
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-menu offset-y v-if="acceptedQuote">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                block
                outlined
                rounded
                :color="isAmendment ? 'info' : 'primary'"
                v-bind="attrs"
                v-on="on"
              >
                Quote Actions
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isOrganization"
                v-clipboard:copy="shareLink"
                v-clipboard:success="onCopy"
                to="#"
              >
                Share Quote
              </v-list-item>
              <v-list-item
                v-if="isOrganization"
                class="cursor-pointer"
                :href="shareLink"
                target="_blank"
              >
                Open/Print Quote
              </v-list-item>
              <v-list-item
                v-if="
                  acceptedQuote.quoteStatus ==
                    quoteStatuses.pendingApproval.id ||
                  acceptedQuote.quoteStatus == quoteStatuses.booked.id ||
                  acceptedQuote.quoteStatus == quoteStatuses.inProgress.id ||
                  acceptedQuote.quoteStatus ==
                    quoteStatuses.amendmentPending.id ||
                  acceptedQuote.quoteStatus == quoteStatuses.proForma.id
                "
                :to="`/quotes/${quoteRequestProp.uniqueId}?openMessage=true`"
              >
                Message Workshop
              </v-list-item>
              <v-list-item
                v-if="
                  isOrganization &&
                  (acceptedQuote.quoteStatus ==
                    quoteStatuses.pendingApproval.id ||
                    acceptedQuote.quoteStatus == quoteStatuses.booked.id ||
                    acceptedQuote.quoteStatus == quoteStatuses.inProgress.id ||
                    acceptedQuote.quoteStatus ==
                      quoteStatuses.amendmentPending.id ||
                    acceptedQuote.quoteStatus == quoteStatuses.proForma.id)
                "
                :to="`/quotes/${quoteRequestProp.uniqueId}?openOrder=true`"
              >
                Attach Order
              </v-list-item>
              <v-list-item
                v-if="
                  isOrganization &&
                  acceptedQuote.quoteStatus == quoteStatuses.booked.id
                "
                @click="openCancelBookingDialog"
              >
                Cancel Booking
              </v-list-item>
            </v-list>
          </v-menu>

          <v-snackbar
            app
            :color="isAmendment ? 'info' : 'primary'"
            v-model="copySnackbar"
            :timeout="3000"
            centered
          >
            Shareable quote link copied to clipboard
          </v-snackbar>

          <v-dialog
            v-if="acceptedQuote"
            v-model="cancelQuoteDialog"
            persistent
            content-class="smc-dialog"
          >
            <validation-observer ref="cancel-quote-validation-observer">
              <v-form lazy-validation>
                <v-card class="smc-card-dialog">
                  <v-card-title
                    class="primary white--text smc-card-dialog-title"
                  >
                    <span class="mx-auto">Cancel Booking</span>
                  </v-card-title>
                  <div class="smc-card-top-bar">
                    <v-btn
                      icon
                      color="midGrey"
                      class="float-right"
                      height="32"
                      width="32"
                      @click="cancelQuoteDialog = false"
                    >
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                  </div>
                  <v-sheet class="smc-card-dialog-content-sheet">
                    <v-card-text>
                      <p>
                        Cancel quote for
                        <strong>{{ acceptedQuote.workshop.name }}</strong>
                        ?
                      </p>
                      <v-row>
                        <v-col cols="12" sm="6" offset="0">
                          <v-img
                            :src="acceptedQuote.workshop.logoUrl"
                            :lazy-src="acceptedQuote.workshop.logoUrl"
                          ></v-img>
                        </v-col>
                        <v-col cols="12" sm="6" class="position-relative">
                          <span
                            class="primary--text title font-weight-bold position-absolute horizontal-vertical-center quote-total"
                          >
                            {{ acceptedQuote.quoteTotalIncl | currency }}
                          </span>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <p class="mt-4 mb-0">
                      Please provide a reason for the cancellation.
                    </p>
                    <v-row>
                      <v-col class="mx-4 mt-4">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Note"
                          id="workshopNote"
                          rules="required"
                        >
                          <v-textarea
                            v-model="cancelQuoteMessage"
                            :error-messages="errors"
                            label="Reason for cancellation"
                            counter="160"
                            rows="3"
                            outlined
                            no-resize
                            auto-grow
                            persistent-hint
                            maxLength="160"
                          >
                          </v-textarea>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-sheet>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-row>
                      <v-col>
                        <v-btn
                          color="darkGrey"
                          rounded
                          outlined
                          class="smc-medium-button"
                          @click="cancelQuoteDialog = false"
                        >
                          Cancel
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-btn
                          color="primary"
                          rounded
                          class="smc-medium-button"
                          :loading="cancelQuoteLoading"
                          @click="submitCancelQuoteBooking"
                        >
                          Submit
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-form>
            </validation-observer>
          </v-dialog>

          <v-dialog
            v-model="showPricesDialog"
            persistent
            scrollable
            content-class="smc-dialog smc-dialog-inset smc-dialog-ultrawide"
          >
            <v-card class="smc-card-dialog smc-card-dialog-inset">
              <v-card-title
                class="smc-card-dialog-title-inset primary white--text"
              >
                <span class="mx-auto"> Prices </span>
              </v-card-title>
              <div class="smc-card-top-bar smc-card-top-bar-inset"></div>
              <v-card-text>
                <quote-price-lookup
                  :quoteRequestProp="quoteRequestProp"
                ></quote-price-lookup>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  @click="showPricesDialog = false"
                  color="primary"
                  rounded
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import _ from "lodash";
import textHighlight from "vue-text-highlight";
import vehicleDetails from "@/components/vehicle-details";
import quotePriceLookup from "@/components/quote-price-lookup";

import { dateHelper, quoteRequestStatuses, quoteStatuses } from "@/helpers";
import { quoteRequestService, quoteService } from "@/services";
import validate from "@/mixins/validator";
import QuoteAccept from "@/models/quote-accept";

export default {
  name: "active-request-tab-item",
  mixins: [validate],
  components: {
    textHighlight,
    vehicleDetails,
    quotePriceLookup,
  },
  props: {
    enableCompleteButtonProp: Boolean,
    quoteRequestProp: Object,
    searchQueryProp: Array,
  },
  data: () => ({
    loading: false,
    quoteStatuses,
    quoteRequestStatuses,
    dateHelper,
    completeJobShow: false,
    copySnackbar: false,
    workshopMappingsIsOpen: false,
    vehicleDetailsShow: false,

    cancelQuoteMessage: "",
    cancelQuoteDialog: false,
    cancelQuoteLoading: false,

    showPricesDialog: false,
    showPricesLoading: false,
  }),
  computed: {
    cardStatusClass() {
      let clss = "";
      if (this.isInspection) {
        clss = `${clss} inspection-card`;
      }
      if (this.isCancelled) {
        clss = `${clss} disabled-card`;
      }
      return clss;
    },
    isOrganization() {
      return this.$store.getters["user/isOrganization"];
    },
    timeSinceRequested() {
      return (
        new Date() - dateHelper.convertToDate(this.quoteRequestProp.dateCreated)
      );
    },
    anyQuotes() {
      return (
        this.quoteRequestProp &&
        this.quoteRequestProp.quotes &&
        this.quoteRequestProp.quotes.length > 0
      );
    },
    orderAttached() {
      if (this.anyQuotes) {
        if (this.acceptedQuote) {
          return this.acceptedQuote.organizationOrderAttached;
        } else {
          // return true if any of the quoteRequestProp.quotes has an organizationOrderAttached
          return this.quoteRequestProp.quotes.some(
            (quote) => quote.organizationOrderAttached
          );
        }
      }
      return false;
    },
    acceptedQuote() {
      var quote = undefined;

      quote = _.find(this.quoteRequestProp.quotes, function (q) {
        return q.quoteStatus > 0;
      });

      return quote;
    },
    isInspection() {
      return (
        this.quoteRequestProp.quoteType == "Inspection" &&
        (!this.acceptedQuote ||
          this.acceptedQuote.quoteStatus == quoteStatuses.booked.id)
      );
    },
    isCancelled() {
      return (
        this.quoteRequestProp.quoteRequestStatus ==
        quoteRequestStatuses.cancelled.id
      );
    },
    isCompleted() {
      return (
        this.quoteRequestProp.quoteRequestStatus ==
        quoteRequestStatuses.completed.id
      );
    },
    isAmendment() {
      var hasAmendment =
        this.acceptedQuote &&
        this.acceptedQuote.quoteStatus == quoteStatuses.amendmentPending.id;
      return hasAmendment;
    },
    isInvoiced() {
      return (
        this.acceptedQuote &&
        (this.acceptedQuote.quoteStatus == quoteStatuses.proForma.id ||
          this.acceptedQuote.quoteStatus == quoteStatuses.invoiced.id ||
          this.acceptedQuote.quoteStatus == quoteStatuses.completed.id)
      );
    },
    quoteStatusLabel() {
      let label = "";
      if (this.acceptedQuote) {
        let statusKey = _.findKey(quoteStatuses, [
          "id",
          this.acceptedQuote.quoteStatus,
        ]);
        label = quoteStatuses[statusKey].label;
      }
      return label;
    },
    shareLink() {
      if (!this.acceptedQuote) {
        return "";
      }
      return `${process.env.VUE_APP_QUOTE_VIEWER_URL}/quote?quoteId=${this.acceptedQuote.uniqueId}`;
    },
  },
  methods: {
    getQuoteRequestDisplayColor() {
      if (this.isAmendment) {
        return "info";
      } else if (this.isInspection) {
        return "orange";
      }
      return "primary";
    },
    async completeQuote() {
      this.loading = true;
      var result = await quoteRequestService.completeQuoteRequest(
        this.quoteRequestProp.id
      );
      if (result.status == 200) {
        this.$emit("resetRequests");
      }
      this.loading = false;
    },
    openCancelBookingDialog() {
      this.cancelQuoteDialog = true;
    },
    async submitCancelQuoteBooking() {
      if (await this.$refs["cancel-quote-validation-observer"].validate()) {
        this.cancelQuoteLoading = true;
        var user = this.$store.getters["user/getUserModel"];

        var result = await quoteService.cancelBooking(
          new QuoteAccept(
            this.acceptedQuote.id,
            user,
            this.acceptedQuote.dateModifiedUtc,
            this.cancelQuoteMessage,
            ""
          )
        );

        if (result.status == 200) {
          this.$router.go(this.$router.currentRoute);
        }

        this.cancelQuoteLoading = false;
      }
    },
    onCopy: function () {
      this.copySnackbar = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.inspection-card {
  border: 2px solid #ff9800 !important;
}

.inspection-card .smc-card-dialog-title-inset {
  border-top-left-radius: 25px !important;
}

.disabled-card .smc-card-dialog-title-inset {
  border-top-left-radius: 25px !important;
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.disabled-card .v-card__text {
  opacity: 0.4;
}

.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 200px;
  height: 50px;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0;
    border-top-right-radius: 30px;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

.smc-card-dialog.v-card > .smc-card-top-bar-inset {
  top: 0;
}

.quote-status-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}

.workshop-mappings-list {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
