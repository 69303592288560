export default class QuoteRequestItem {
    constructor(
        id,
        name,
        hasQuery,
        query,
        queryAnswer,
        quoteItems,
        labourItem,
        quoteRequestItemNotes,
        amendmentAccepted,
        keyword
    ) {
        this.id = id;
        this.name = name;
        this.hasQuery = hasQuery;
        this.query = query;
        this.queryAnswer = queryAnswer;
        this.quoteItems = quoteItems;
        this.labourItem = labourItem;
        this.quoteRequestItemNotes = quoteRequestItemNotes;
        this.amendmentAccepted = amendmentAccepted;
        this.keyword = keyword;
    }
}