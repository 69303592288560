import _ from "lodash";

const addPhonePrefix = phoneNumber => {
    return `+27${_.replace(
      phoneNumber.substring(1),
      new RegExp("\\s", "g"),
      ""
    )}`;
  };
  
  const removePhonePrefix = phoneNumber => {
    if (_.startsWith(phoneNumber, "27")) {
      return `0${phoneNumber.substr(3, 2)} ${phoneNumber.substr(
        5,
        3
      )} ${phoneNumber.substr(8, 4)}`;
    } else {
      return phoneNumber;
    }
  };

  export const phoneHelper = {
    addPhonePrefix,
    removePhonePrefix
  };