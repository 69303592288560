<template>
  <v-card color="white" class="smc-card-dialog mt-4">
    <v-card-title class="smc-card-dialog-title-inset primary white--text">
      <span class="mx-auto">
        <text-highlight
          :queries="searchQueryProp"
          highlightClass="search-highlight"
        >
          {{ vehicleMapping.name ? vehicleMapping.name : "VEHICLE" }}
        </text-highlight>
      </span>
    </v-card-title>
    <v-card-subtitle class="smc-card-dialog-subtitle-inset">
      <span class="font-weight-medium">
        {{ dateHelper.formatDateLong(vehicleMapping.dateCreatedUtc) }}
      </span>
    </v-card-subtitle>
    <v-card-text class="pa-4">
      <v-row>
        <v-col class="vehicle-image-col">
          <v-avatar class="ma-3" size="125" width="90%" tile>
            <img
              v-if="vehicleMapping.vehicle.imageUrl"
              :src="vehicleMapping.vehicle.imageUrl"
              class="smc-search-result-image"
            />
            <img
              v-else
              :src="require('@/assets/images/no-entry-for-motor-vehicle.png')"
              class="smc-search-result-image"
            />
          </v-avatar>
          <v-row>
            <v-col>
              <v-dialog v-model="vehicleDetailsShow" width="800">
                <v-toolbar color="primary" dark height="48">
                  Vehicle Details
                </v-toolbar>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    secondary
                    rounded
                    outlined
                    color="info"
                    class="px-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Vehicle Details
                  </v-btn>
                </template>
                <v-card>
                  <vehicle-details
                    :vehicleMappingProp="vehicleMapping"
                    :hideImageProp="true"
                  ></vehicle-details>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      rounded
                      class="px-4"
                      @click="vehicleDetailsShow = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="mileageHistoryShow"
                width="800"
                v-if="
                  vehicleMapping.customerVehicleMappingMileageHistories &&
                  vehicleMapping.customerVehicleMappingMileageHistories.length >
                    0
                "
              >
                <v-toolbar color="primary" dark height="48">
                  Mileage History
                </v-toolbar>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    secondary
                    rounded
                    outlined
                    class="ml-1 px-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Mileage History
                  </v-btn>
                </template>
                <v-card>
                  <customer-vehicle-mileage-history
                    ref="customerVehicleMileageHistory"
                    v-bind:mileageHistoryDataProp="
                      vehicleMapping.customerVehicleMappingMileageHistories
                    "
                    v-bind:vehicleProp="vehicleMapping.vehicle"
                    v-bind:vehicleMappingProp="vehicleMapping"
                    v-on:vehicleMappingUpdated="vehicleMappingUpdated"
                  >
                  </customer-vehicle-mileage-history>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      rounded
                      class="px-4"
                      @click="mileageHistoryShow = false"
                    >
                      Close
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-left">
          <v-chip 
            class="float-right"
            v-if="vehicleMapping.status"
            color="primary"
            small
            label
          >
            {{vehicleMapping.status}}
          </v-chip>
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.year }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.brand }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.range }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.model }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.fuelType }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.transmissionType }}
            </text-highlight>
          </strong>
          <br />
          <strong>
            <text-highlight
              v-if="isOrganization && vehicleMapping.vehicle.meadCode"
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              M&M: {{ vehicleMapping.vehicle.meadCode }}
            </text-highlight>
            <text-highlight
              v-else
              :queries="searchQueryProp"
              highlightClass="search-highlight"
            >
              {{ vehicleMapping.vehicle.gearboxType }}
            </text-highlight>
          </strong>
          <br />
        </v-col>
        <v-divider vertical role="presentation" />
        <v-col class="text-left">
          <div v-if="vehicleMapping.innovationPolicy">
            <strong>{{ vehicleMapping.innovationPolicy.policyCode }}</strong>
            <br />
            <strong>{{ vehicleMapping.vehicleVinNumber }}</strong>
            <br />
            <strong>
              Start date:
              {{
                dateHelper.formatDateShort(
                  vehicleMapping.innovationPolicy.startDate
                )
              }}
            </strong>
            <br />
            <strong>
              End date:
              {{
                dateHelper.formatDateShort(
                  vehicleMapping.innovationPolicy.endDate
                )
              }}
            </strong>
            <br />
            <strong>
              End distance:
              {{ vehicleMapping.innovationPolicy.endDistance }} km</strong
            >
            <router-link to="#">
              <v-img
                class="innovation-logo mt-2"
                width="160"
                contain
                v-ripple
                :src="require('@/assets/images/ig-logo.png')"
              />
            </router-link>
          </div>
          <div v-else>
            <strong>{{ vehicleMapping.vehicleColour }}</strong>
            <br />
            <strong>
              <text-highlight
                :queries="searchQueryProp"
                highlightClass="search-highlight"
              >
                {{ vehicleMapping.vehicleVinNumber }}
              </text-highlight>
            </strong>
            <br />
            <strong>
              <text-highlight
                :queries="searchQueryProp"
                highlightClass="search-highlight"
              >
                {{ vehicleMapping.vehicleEngineNumber }}
              </text-highlight>
            </strong>
            <br />
            <strong>
              <text-highlight
                :queries="searchQueryProp"
                highlightClass="search-highlight"
              >
                {{ vehicleMapping.vehicleLicensePlate }}
              </text-highlight>
            </strong>
          </div>
          <v-row
            no-gutters
            v-if="vehicleMapping.vehicle.vehicleAttachments"
            class="mt-2"
          >
            <v-col>
              <div
              v-for="(attachment, index) in vehicleMapping.vehicle.vehicleAttachments"
              :key="index"
              >
                <pdf-viewer
                  v-if="attachmentIsPdf(attachment)"
                  :pdfUrlProp="attachmentUrl(attachment)"
                  :pdfFilenameProp="attachmentFileName(attachment.attachmentFileName)"
                  :pdfAllPagesProp="true"
                >
                </pdf-viewer>
                <v-btn
                  v-else
                  small
                  outlined
                  rounded
                  style="max-width: 200px"
                  @click="downloadVehicleAttachment(attachment)"
                  class="mb-1"
                >
                  {{ attachmentFileName(attachment.attachmentFileName) }}
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-divider
          vertical
          role="presentation"
          v-if="vehicleMapping.innovationPolicy"
        />
        <v-col class="actions-col">
          <div
            v-if="vehicleMapping.innovationPolicy"
            class="policy-button-wrapper"
          >
            <v-btn
              @click.stop="newPolicy"
              :color="
                vehicleMapping.innovationPolicy.policyStatus ==
                innovationPolicyStatuses.inactive.value
                  ? 'secondary lighten-1'
                  : 'primary'
              "
              rounded
              block
              class="mb-2"
            >
              <span>New Booking</span>
            </v-btn>
            <popper
              v-if="innovationPolicyWarning.level !== ''"
              trigger="hover"
              :options="{
                placement: 'top',
              }"
            >
              <div class="popper">{{ innovationPolicyWarning.message }}</div>
              <v-btn
                @click.stop="newPolicy"
                slot="reference"
                elevation="0"
                class="policy-warning-icon"
                rounded
                fab
                width="26"
                height="26"
              >
                <v-icon class="policy-danger"> mdi-alert-circle </v-icon>
              </v-btn>
            </popper>
          </div>
          <v-btn @click="newQuote" color="primary" rounded block v-else>
            <span>New Request</span>
          </v-btn>
          <v-dialog
            v-model="editVehicleShow"
            max-width="550"
            transition="dialog-top-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="register-submit"
                color="primary"
                block
                outlined
                :loading="editVehicleLoading"
                rounded
                class="smc-medium-button mt-2"
                v-bind="attrs"
                v-on="on"
              >
                Edit Vehicle
              </v-btn>
            </template>
            <customer-vehicle-edit
              v-bind:vehicleMappingProp="vehicleMapping"
              v-bind:canSearchVehicleProp="false"
              v-bind:editVehicleCaptureMileageProp="editVehicleCaptureMileage"
              v-on:cancelVehicleMappingEdit="editVehicleShow = false"
              v-on:updateVehicleMapping="updateVehicleMapping"
              v-on:showVehicleMileageHistory="updateMileageHistoryShow()"
              v-on:closeCustomerVehicleEdit="closeCustomerVehicleEdit"
            >
            </customer-vehicle-edit>
          </v-dialog>
          <v-btn
            :to="`/quote-requests/${vehicleMapping.vehicleId}`"
            class="mt-2"
            color="primary"
            block
            outlined
            rounded
          >
            History
            <v-btn
              color="secondary"
              class="ml-2"
              rounded
              fab
              width="26"
              height="26"
            >
              {{ vehicleMapping.quoteRequestsCount }}
            </v-btn>
          </v-btn>

          <v-dropdown
            v-if="
              vehicleMapping.activeQuotesCount &&
              vehicleMapping.activeQuotesCount != 0
            "
            :distance="6"
            container="#smc-app"
          >
            <!-- This will be the popover reference (for the events and position) -->
            <v-btn class="mt-2" color="primary" block outlined rounded>
              Active
              <v-btn
                color="primary"
                class="ml-2"
                rounded
                fab
                width="26"
                height="26"
              >
                {{ vehicleMapping.activeQuotesCount }}
              </v-btn>
            </v-btn>

            <!-- This will be the content of the popover -->
            <template #popper>
              <customer-vehicle-active-quotes
                v-bind:vehicleIdProp="vehicleMapping.vehicleId"
              />
            </template>
          </v-dropdown>
        </v-col>
      </v-row>
    </v-card-text>

    <v-dialog
      v-if="vehicleMapping.innovationPolicy"
      v-model="innovationPolicyWarningDialogShow"
      max-width="400"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar color="primary" dark height="48" class="mb-6">
          {{ innovationPolicyWarning.title }}
        </v-toolbar>
        <v-card-text>
          <v-card
            color="orange"
            style="
              position: absolute;
              left: 0;
              right: 0;
              top: 48px;
              height: 110px;
            "
          >
            <v-card-text style="color: white" class="pb-0">
              <v-icon x-large style="color: white">mdi-alert-circle</v-icon>
            </v-card-text>
            <v-card-text style="color: white" class="pt-0">
              <p style="padding-top: 15px" class="mb-0">Please note</p>
            </v-card-text>
          </v-card>
          <div style="margin-top: 134px">
            <v-row>
              <v-col>
                <p class="mb-0">{{ innovationPolicyWarning.message }}</p>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            rounded
            class="smc-text-button smc-small-button"
            color="secondary"
            @click="innovationPolicyWarningDialogShow = false"
          >
            Cancel
          </v-btn>
          <v-btn
            class="smc-text-button smc-small-button"
            color="primary"
            rounded
            @click="newPolicyConfirmed"
            :disabled="
              vehicleMapping.innovationPolicy.policyStatus ==
              innovationPolicyStatuses.inactive.value
            "
          >
            NEW REQUEST
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import _ from "lodash";
import popper from "vue-popperjs";
import textHighlight from "vue-text-highlight";
import "vue-popperjs/dist/vue-popper.css";
import { dateHelper, innovationPolicyStatuses } from "@/helpers";
import { vehicleService } from "@/services";

import customerVehicleEdit from "./customer-vehicle-edit";
import customerVehicleMileageHistory from "./customer-vehicle-mileage-history";
import customerVehicleActiveQuotes from "./customer-vehicle-active-quotes";
import vehicleDetails from "@/components/vehicle-details";
import pdfViewer from "@/components/widgets/pdf-viewer";

export default {
  components: {
    popper,
    customerVehicleEdit,
    customerVehicleMileageHistory,
    customerVehicleActiveQuotes,
    textHighlight,
    vehicleDetails,
    pdfViewer
  },
  name: "customer-vehicle",
  props: {
    vehicleMappingProp: Object,
    searchQueryProp: Array,
  },
  data: () => ({
    dateHelper,
    innovationPolicyStatuses,

    vehicleMapping: undefined,
    editVehicleLoading: false,

    editVehicleShow: false,
    editVehicleCaptureMileage: false,
    mileageHistoryShow: false,
    vehicleDetailsShow: false,
    
    innovationPolicyWarningDialogShow: false,
    innovationPolicyWarning: {
      level: "",
      title: "",
      message: "",
    },
  }),
  computed: {
    isOrganization() {
      return this.$store.getters["user/isOrganization"];
    },
  },
  created() {
    this.vehicleMapping = _.cloneDeep(this.vehicleMappingProp);
    if (this.vehicleMappingProp && this.vehicleMappingProp.innovationPolicy) {
      let status = this.vehicleMappingProp.innovationPolicy.policyStatus;
      switch (status) {
        case innovationPolicyStatuses.expired.value:
          this.vehicleMapping.innovationPolicy = null;
          break;
        case innovationPolicyStatuses.cancelled.value:
          this.vehicleMapping.innovationPolicy = null;
          break;
        case innovationPolicyStatuses.suspened.value:
          this.innovationPolicyWarning = innovationPolicyStatuses.suspened;
          break;
        case innovationPolicyStatuses.inactive.value:
          this.innovationPolicyWarning = innovationPolicyStatuses.inactive;
          break;
        case innovationPolicyStatuses.hold.value:
          this.innovationPolicyWarning = innovationPolicyStatuses.hold;
          break;
        case innovationPolicyStatuses.cancellationProgress.value:
          this.innovationPolicyWarning =
            innovationPolicyStatuses.cancellationProgress;
          break;
        case innovationPolicyStatuses.cancellationPendingRecovery.value:
          this.innovationPolicyWarning =
            innovationPolicyStatuses.cancellationPendingRecovery;
          break;
        case innovationPolicyStatuses.cancellationPendingCollections.value:
          this.innovationPolicyWarning =
            innovationPolicyStatuses.cancellationPendingCollections;
          break;
        case innovationPolicyStatuses.cancellationRequested.value:
          this.innovationPolicyWarning =
            innovationPolicyStatuses.cancellationRequested;
          break;
      }
    }
  },
  watch: {
    vehicleMappingProp(val) {
      this.vehicleMapping = _.cloneDeep(val);
    },
  },
  methods: {
    captureVehicleMileage() {
      this.editVehicleCaptureMileage = true;
      this.editVehicleShow = true;
    },
    updateVehicleMapping(updateVehicleMapping) {
      this.$emit("updateVehicleMapping", updateVehicleMapping);
    },
    closeCustomerVehicleEdit() {
      this.editVehicleShow = false;
    },
    newPolicy() {
      if (this.innovationPolicyWarning.level !== "") {
        this.innovationPolicyWarningDialogShow = true;
      } else {
        this.newPolicyConfirmed();
      }
    },
    newPolicyConfirmed() {
      this.$router.push({
        name: "new-quote",
        params: {
          vehicleMappingProp: this.vehicleMapping,
          isPolicyQuoteProp: true,
        },
      });
    },
    newQuote() {
      this.$router.push({
        name: "new-quote",
        params: { vehicleMappingProp: this.vehicleMapping },
      });
    },
    updateMileageHistoryShow() {
      this.mileageHistoryShow = true;
      this.$nextTick(() => {
        this.$refs.customerVehicleMileageHistory.loadServiceCheck();
      });
    },
    vehicleMappingUpdated(vehicleMapping) {
      this.vehicleMapping = vehicleMapping;
    },
    attachmentFileName(fileName) {
      if (fileName.length <= 20) {
        return fileName;
      } else {
        const extensionIndex = fileName.lastIndexOf(".");
        if (extensionIndex > 0) {
          const basename = fileName.substring(0, extensionIndex);
          const extension = fileName.substring(extensionIndex);
          const truncatedName =
            basename.substring(0, 18 - extension.length) + "..";
          return truncatedName + extension;
        } else {
          // If there's no file extension, simply truncate to 20 characters
          return fileName.substring(0, 18) + "..";
        }
      }
    },
    attachmentUrl(attachment) {
      if(!attachment) {
        return "";
      }
      let contentUrl = process.env.VUE_APP_SMC_CONTENT_URL;
      let fileName = this.attachmentFileName(attachment.attachmentFileName);
      let filePath = `vehicles/${this.vehicleMappingProp.vehicleId}/VehicleReport`;
      return `${contentUrl}/${filePath}/${fileName}`;
    },
    attachmentIsPdf(attachment) {
      var isPdf = false;
      if (attachment && attachment.attachmentFileName) {
        isPdf = _.endsWith(attachment.attachmentFileName, ".pdf");
      }
      return isPdf;
    },
    async downloadVehicleAttachment(attachment) {
      await vehicleService.downloadVehicleAttachment(attachment);
    },
  },
};
</script>

<style lang="scss" scoped>
.smc-card-avatar {
  @media screen and (max-width: 600px) {
    margin-left: auto;
    margin-right: auto;
  }
}

.policy-button-wrapper {
  position: relative;
}

.policy-warning-icon {
  background-color: #fff;
  position: absolute;
  right: -10px;
  top: -10px;
}

.v-btn .policy-danger {
  color: #ed3237;
}

.v-btn .policy-warning {
  color: orange;
}

.smc-card-dialog.v-card > .v-card__title.smc-card-dialog-title-inset {
  width: fit-content;
  min-width: 250px;
  padding: 10px 20px;
  border-top-left-radius: 30px !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 32px !important;

  @media screen and (max-width: 600px) {
    width: 100%;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 30px !important;
  }
}

.smc-card-dialog.v-card > .v-card__subtitle.smc-card-dialog-subtitle-inset {
  @media screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    border-bottom-right-radius: 32px;
  }
}

@media screen and (min-width: 601px) {
  .vehicle-image-col {
    max-width: 298px;
  }

  .actions-col {
    max-width: 195px;
  }
}
</style>
