<template>
  <div class="home container" :class="$vuetify.breakpoint.xsOnly ? 'px-0' : ''">
    <background-parallax :backgroundOpacityProp="'0.6'" />
    <div class="mt-12 pt-12" style="position: relative"></div>

    <v-sheet class="mb-12 box box-rounded" elevation="6">
      <box-view-skeleton-loader v-if="loading" class="mb-6" />
      <quote-request-summary
        v-else
        :quoteRequestProp="quoteRequest"
        class="mb-6"
      />
    </v-sheet>

    <v-card
      class="smc-box box box-rounded box-transparent box-transparent-light"
      elevation="12"
    >
      <v-card-subtitle>
        <v-breadcrumbs class="smc-breadcrumbs">
          <v-breadcrumbs-item class="smc-breadcrumbs-item" to="/">
            <v-icon>mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item
            v-if="isOrganization"
            class="smc-breadcrumbs-item"
            to="/active-requests"
          >
            Active Requests
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            v-else
            class="smc-breadcrumbs-item"
            to="/my-garage"
          >
            My Garage
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item class="smc-breadcrumbs-item" :to="`/quote-requests${quoteRequest.vehicleId ? `/${quoteRequest.vehicleId}` : ''}`">
            Vehicle History
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item class="smc-breadcrumbs-item">
            <span v-if="isInvoice">Invoice</span>
            <span v-else>Responses</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
        <div class="sort-container">
          Sort by:
          <v-menu bottom open-on-hover offset-y transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-chip v-on="on">{{ sortModel.selectedSort.text }}</v-chip>
            </template>
            <v-sheet class="px-4 py-2 box" style="width: 360px">
              <v-radio-group loading v-model="sortModel.selectedSort">
                <v-row>
                  <v-col>
                    <v-radio
                      :label="sortOptions.dateSubmitted.text"
                      :value="sortOptions.dateSubmitted"
                      class="mb-4"
                    ></v-radio>
                    <!--<v-radio
                      :label="sortOptions.totalPrice.text"
                      :value="sortOptions.totalPrice"
                      class="my-4"
                    ></v-radio>-->
                  </v-col>
                  <!--<v-divider vertical />
                  <v-col>
                    <v-radio
                      :label="sortOptions.distance.text"
                      :value="sortOptions.distance"
                      class="mb-4"
                    ></v-radio>
                    <v-radio
                      :label="sortOptions.rating.text"
                      :value="sortOptions.rating"
                      class="my-4"
                    ></v-radio>
                  </v-col>-->
                </v-row>
              </v-radio-group>
            </v-sheet>
          </v-menu>
          <v-hover v-slot:default="{ hover }">
            <v-btn icon @click="invertSort">
              <v-icon :color="hover ? 'primary' : 'default'">
                {{
                  sortModel.dir == "desc"
                    ? "mdi-arrow-down-thick"
                    : "mdi-arrow-up-thick"
                }}
              </v-icon>
            </v-btn>
          </v-hover>
        </div>
      </v-card-subtitle>
      <v-card-text class="quote-view-card-text">
        <div v-if="loading">
          <box-view-skeleton-loader
            v-for="i in [1, 2, 3]"
            :key="i"
            class="mb-6"
          />
        </div>

        <quote-view
          class="mb-6"
          v-for="(item, i) in sortedQuotes"
          :key="item.id"
          :quoteProp="item"
          :userEmailProp="quoteRequest.applicationUser.email"
          :userProp="quoteRequest.applicationUser"
          :addressProp="quoteRequest.address"
          :quoteCountProp="i + 1"
          :smcReferenceProp="quoteRequest.reference"
          :hidePriceProp="quoteRequest.hidePrice"
          :policyQuoteProp="quoteRequest.quoteType"
          :quoteRequestProp="quoteRequest"
          :quoteRequestOrganizationShareProp="
            quoteRequest.quoteRequestOrganizationBranchShare
          "
          @updateOrganizationShare="updateOrganizationShare"
        ></quote-view>
        <p v-if="!loading && quoteRequest.quotes.length == 0">
          No responses received yet
        </p>
        <!-- <naked-insurance-banner
          v-if="!loading && quoteRequest.quotes.length > 0"
          :analyticsLabelProp="'quotesAdvert'"
          :borderRadiusProp="true"
        /> -->
      </v-card-text>
    </v-card>

    <!-- Dialogs -->
    <v-dialog v-model="newQuotesDialog" content-class="smc-dialog" persistent>
      <v-card class="smc-card-dialog">
        <v-card-title class="primary white--text smc-card-dialog-title">
          <span class="mx-auto">Did you know?</span>
        </v-card-title>
        <div class="smc-card-top-bar">
          <v-btn
            icon
            color="midGrey"
            class="float-right"
            height="32"
            width="32"
            @click="newQuotesDialog = false"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
        <v-card-subtitle class="mt-0 title">
          It's always cheaper to book on our platform!
        </v-card-subtitle>
        <v-card-subtitle>
          Service My Car has negotiated preferential labour and parts prices
          with many of our Workshop Partners. Therefore, if you don’t book
          through our platform and approach the workshop directly (outside of
          the Service My Car) you may be quoted a higher price.
        </v-card-subtitle>
        <v-card-subtitle>
          Our platform is completely FREE to make use of and there are no hidden
          costs or fees.
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="smc-medium-button"
            rounded
            @click="newQuotesDialogAccept()"
          >
            Got it
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showEmailDialog" content-class="smc-dialog" persistent>
      <ValidationObserver ref="validationObserver">
        <v-form
          v-model="emailFormValid"
          ref="form"
          lazy-validation
          @submit="submitEmail($event)"
        >
          <v-card class="smc-card-dialog">
            <v-card-title class="primary white--text smc-card-dialog-title">
              <span class="mx-auto">Email address</span>
            </v-card-title>
            <div class="smc-card-top-bar">
              <v-btn
                text
                rounded
                color="midGrey"
                class="float-right"
                height="32"
                @click="redirectHome"
              >
                Cancel
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </div>
            <v-card-subtitle class="mt-0 pt-0 subtitle-1">
              Please enter the email address you used when<br />submitting this
              request.
            </v-card-subtitle>
            <v-card-text>
              <ValidationProvider
                v-slot="{ errors }"
                name="Email address"
                rules="required|email"
              >
                <v-text-field
                  v-model="emailAddress"
                  :error-messages="
                    emailAddressErrors.length > 0 ? emailAddressErrors : errors
                  "
                  rounded
                  outlined
                  label="Email address"
                ></v-text-field>
              </ValidationProvider>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-spacer></v-spacer>
                    <v-btn
                      type="submit"
                      :loading="emailDialogLoading"
                      color="primary"
                      class="smc-medium-button"
                      rounded
                    >
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <p class="mx-auto mb-0 body-2 secondary--text">
                      Alternatively you can
                      <v-btn
                        id="login-forgotPassword"
                        tile
                        text
                        @click="loginDialog = true"
                        class="primary--text text-none smc-text-button"
                      >
                        Login
                      </v-btn>
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>

    <v-dialog v-model="loginDialog" persistent content-class="smc-dialog">
      <v-card class="smc-card-dialog" color="white">
        <v-card-title
          class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
        >
          <span class="mx-auto">LOGIN</span>
        </v-card-title>
        <div class="smc-card-top-bar">
          <v-btn
            icon
            color="midGrey"
            class="float-right"
            height="32"
            width="32"
            @click="loginDialog = false"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <p>
            You need to have an account in order to accept. Please either login,
            or register.
          </p>
          <account-login
            :returnUrlProp="this.$route.fullPath"
            @loginCancel="loginDialog = false"
            @forgotPassword="
              loginDialog = false;
              forgotPasswordDialog = true;
            "
          ></account-login>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="forgotPasswordDialog"
      persistent
      content-class="smc-dialog"
      :width="$vuetify.breakpoint.smAndUp ? '650' : '100vw'"
    >
      <v-card class="smc-card-dialog" color="white">
        <v-card-title
          class="primary white--text text-center smc-card-dialog-title smc-card-dialog-title-narrow"
        >
          <span class="mx-auto">RESET</span>
        </v-card-title>
        <div class="smc-card-top-bar">
          <v-btn
            icon
            color="midGrey"
            class="float-right"
            height="32"
            width="32"
            @click="forgotPasswordDialog = false"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <account-forgot-password
            :returnUrlProp="this.$route.fullPath"
            @resetForgotPassword="
              forgotPasswordDialog = false;
              loginDialog = true;
            "
          ></account-forgot-password>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="emailInvalidSnackbar"
      timeout="-1"
      color="primary"
      elevation="12"
    >
      This request was not submitted by the currently logged in user.
    </v-snackbar>
  </div>
</template>

<script>
import _ from "lodash";
import validate from "@/mixins/validator";
import { quoteRequestHelper, quoteStatuses, quoteTypes } from "@/helpers";
import backgroundParallax from "@/components/widgets/background-parallax";
import boxViewSkeletonLoader from "@/components/widgets/box-view-skeleton-loader";
import quoteRequestSummary from "@/components/quote-request-summary";
import accountLogin from "@/components/account/account-login";
import accountForgotPassword from "@/components/account/account-forgot-password";
import quoteView from "@/components/quote-view";
import { quoteRequestService } from "@/services";
//import nakedInsuranceBanner from "@/components/widgets/naked-insurance-banner";

export default {
  name: "quotes",
  mixins: [validate],
  components: {
    backgroundParallax,
    boxViewSkeletonLoader,
    quoteRequestSummary,
    quoteView,
    accountLogin,
    accountForgotPassword,
    //nakedInsuranceBanner,
  },
  data: () => {
    return {
      quoteTypes,
      newQuotesDialog: false,
      loading: false,
      sortModel: {
        dir: "asc",
        selectedSort: {},
      },
      sortOptions: {
        dateSubmitted: { text: "Date recieved", value: "dateCreatedUtc" },
        //totalPrice: { text: "Total price", value: "quoteTotalIncl" },
        //distance: {
        //  text: "Distance",
        //  value: "workshop.distanceMatrix.distance",
        //},
        //rating: { text: "Rating", value: "workshop.rating" },
      },
      quoteRequest: quoteRequestHelper.getEmptyQuoteRequest(),
      emailFormValid: false,
      emailAddress: "",
      emailAddressErrors: [],
      showEmailDialog: false,
      emailDialogLoading: false,
      emailInvalidSnackbar: false,
      loginDialog: false,
      forgotPasswordDialog: false,
    };
  },
  props: {
    quoteRequestIdProp: String,
    showQuoteProp: Boolean,
    emailAddressProp: String,
  },
  computed: {
    isOrganization() {
      return this.$store.getters["user/isOrganization"];
    },
    isInvoice() {
      let invoicedStatusId = quoteStatuses.invoiced.id;
      let completedStatusId = quoteStatuses.completed.id;
      let paidStatusId = quoteStatuses.paid.id;
      let index = _.findLastIndex(this.quoteRequest.quotes, function (x) {
        return (
          x.quoteStatus == invoicedStatusId ||
          x.quoteStatus == completedStatusId ||
          x.quoteStatus == paidStatusId
        );
      });
      return index > -1;
    },
    sortedQuotes() {
      return _.orderBy(
        this.quoteRequest.quotes,
        [this.sortModel.selectedSort.value],
        [this.sortModel.dir]
      );
    },
  },
  watch: {
    quoteRequestIdProp: async function () {
      await this.initializePage();
    },
    emailAddress: function () {
      this.emailAddress = _.trim(this.emailAddress);
    },
  },
  async created() {
    await this.initializePage();
  },
  methods: {
    async initializePage() {
      this.sortModel.selectedSort = this.sortOptions.dateSubmitted;
      if (this.$store.getters["user/isAuthenticated"]) {
        await this.loadQuotes();
      } else if (this.emailAddressProp) {
        this.emailAddress = this.emailAddressProp;
        await this.loadQuotes();
      } else {
        this.loading = true;
        this.showEmailDialog = true;
      }
    },
    async submitEmail($event) {
      $event.preventDefault();
      this.emailAddressErrors = [];

      if (await this.$refs.validationObserver.validate()) {
        await this.loadQuotes();
      }
    },
    async loadQuotes() {
      this.loading = true;
      this.emailDialogLoading = true;

      if (this.quoteRequestIdProp) {
        var email = this.emailAddress;

        if (this.$store.getters["user/isAuthenticated"]) {
          email = this.$store.getters["user/getEmail"];
        }

        email = encodeURIComponent(email);

        var result = await quoteRequestService.getQuoteRequest(
          this.quoteRequestIdProp,
          email
        );

        if (result.status == 400) {
          this.emailDialogLoading = false;
          this.emailAddressErrors.push(result.data.emailAddress[0]);
          if (this.$store.getters["user/isAuthenticated"]) {
            this.emailInvalidSnackbar = true;
          }
        } else {
          this.quoteRequest = result;
          this.loading = false;
          this.emailDialogLoading = false;
          this.showEmailDialog = false;
        }
      } else {
        // redirect to login with returnUrlProp
        this.$router.push({
          name: "home",
        });
      }
    },
    invertSort() {
      if (this.sortModel.dir == "asc") {
        this.sortModel.dir = "desc";
      } else {
        this.sortModel.dir = "asc";
      }
    },
    newQuotesDialogAccept() {
      this.newQuotesDialog = false;
      this.$cookies.set("quotes_notice", true, "1d");
    },
    redirectHome() {
      this.$router.push({
        name: "home",
      });
    },
    updateOrganizationShare(quote) {
      if (this.quoteRequest.quoteRequestOrganizationBranchShare == null) {
        this.quoteRequest.quoteRequestOrganizationBranchShare = {
          quoteId: quote.id,
          workshopName: quote.workshop.name,
          workshopLogoUrl: quote.workshop.logoUrl,
        };
      } else {
        this.quoteRequest.quoteRequestOrganizationBranchShare.quoteId =
          quote.id;
        this.quoteRequest.quoteRequestOrganizationBranchShare.workshopName =
          quote.workshop.name;
        this.quoteRequest.quoteRequestOrganizationBranchShare.workshopLogoUrl =
          quote.workshop.logoUrl;
      }
    },
  },
};
</script>

<style scoped>
@import "../assets/scss/vuetify-elements/card.scss";
@import "../assets/scss/vuetify-elements/sheet.scss";

.page-header {
  position: relative;
}

.smc-breadcrumbs {
  padding-left: 12px;
}

.smc-breadcrumbs .smc-breadcrumbs-item {
  font-weight: 600;
}
</style>

<style lang="scss" scoped>
.sort-container {
  text-align: right;

  @media screen and (min-width: 601px) {
    position: absolute;
    right: 16px;
    top: 26px;
  }
}

.v-card > .v-card__text.quote-view-card-text {
  @media screen and (max-width: 600px) {
    padding: 0;
  }
}
</style>